<template>
  <div>
    <v-dialog v-model="dialog" overlay-opacity="0.8" max-width="736px">
      <template v-slot:activator="{ on }">
        <Button
          id="activate-project-button-c74e1a50"
          v-on="on"
          texting="p2bold mywhite"
          type="primary"
          :size="button_size"
          :handleClick="open"
          >{{
            project.hasActivated && !project.isActiveProject
              ? "re-activate"
              : "activate"
          }}</Button
        >
      </template>
      <v-card v-if="dialog" style="overflow: hidden">
        <v-container
          @click="onClickOutside($event)"
          :style="{ paddingTop: compactHeight ? '0' : '8px' }"
          style="padding-bottom: 0"
        >
          <v-row align="center">
            <v-col cols="7" class="pl-11">
              <Texting type="p2b grey2">Activate Perkfection</Texting>
            </v-col>
            <v-col cols="2" class="pa-0 ml-auto">
              <v-row justify="end">
                <v-col
                  cols="auto"
                  :style="{ paddingBottom: compactHeight ? '8px' : '14px' }"
                  style="padding-right: 35px"
                >
                  <Images
                    id="activate-project-close-icon-de3702b8"
                    name="close.svg"
                    width="34px"
                    height="34px"
                    class="pointer"
                    v-on:click.native="close"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center" style="background-color: #f9f9f9">
            <v-col
              cols="auto"
              class="pl-11"
              :style="{
                paddingTop: compactHeight ? '12px' : '24px',
                paddingBottom: compactHeight ? '0' : '12px',
              }"
              style="padding-right: 6px"
            >
              <Images :name="project.platform + '.svg'" />
            </v-col>
            <v-col cols="auto" style="padding-bottom: 10px">
              <Texting type="p2bold grey2">{{ project.projectTitle }}</Texting>
            </v-col>
            <v-col cols="auto" class="ml-auto" style="padding-right: 45px">
              <Texting type="p1bold grey10" style="opacity: 0.8">{{
                live ? "Live Campaign" : "Indemand"
              }}</Texting>
            </v-col>
          </v-row>
          <form
            ref="form"
            id="AddForm"
            autocomplete="off"
            @submit.prevent="onActivate"
          >
            <v-row v-if="live" align="center" justify="center">
              <v-col
                class="d-flex pl-3 pl-sm-11"
                cols="auto"
                sm="4"
                style="padding-left: 45px"
              >
                <Texting
                  class="d-flex"
                  type="p1bold MainDark"
                  style="opacity: 0.8"
                  >Activation Period</Texting
                >
                <v-tooltip
                  content-class="my-tooltip"
                  color="rgba(38,39,40,0.9)"
                  max-width="320px"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <span style="height: 33px" v-on="on">
                      <div
                        style="height: 33px; width: 33px"
                        class="justify-center d-flex align-center"
                      >
                        <Images
                          class="d-flex"
                          style="
                            position: relative;
                            bottom: 2px;
                            margin-left: 5px;
                          "
                          name="interrogative.svg"
                        />
                      </div>
                    </span>
                  </template>
                  <Texting type="p1n mywhite" style="text-align: center"
                    >You get the best price for a longer period.<br /><br />
                    You can only select a period equal to or less than the
                    remaining campaign period.</Texting
                  >
                  <div class="arrow-tooltip" style="left: 136px"></div>
                </v-tooltip>
              </v-col>
              <v-col
                cols="auto"
                sm="4"
                id="live-dates"
                :style="{
                  paddingTop: compactHeight ? '0' : '12px',
                  paddingBottom: compactHeight ? '0' : '12px',
                }"
                class="align-center d-flex"
              >
                <Images
                  id="activate-project-minusDay-icon-3501c454"
                  @click.native="removeDay"
                  name="Minus_icon.png"
                  class="pointer"
                />
                <div
                  style="
                    padding-top: 15px;
                    padding-left: 8px;
                    padding-right: 8px;
                  "
                >
                  <input
                    @input="onTotalInput($event)"
                    :style="{
                      borderColor: totalInputValid ? '#A7A0B8' : 'red',
                      borderWidth: totalInputValid ? '1px' : '2px',
                    }"
                    style="
                      background-color: #ffffff;
                      max-width: 100px;
                      height: 53px;
                      padding-left: 15px;
                      min-width: 95px;
                      border-radius: 5px;
                      box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.16);
                      border: 1px solid #a7a0b8;
                    "
                    :id="'dates-' + project.projectTitle"
                    class="live-dates-input"
                    type="text"
                  />
                  <!--                                <v-text-field style="max-width: 100px;max-height: 53px;min-width: 95px;box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.16)" suffix="days" id="dates" v-model="totalDays" single-line :rules="[rules.required, rules.counter, rules.correct]" type="number" hide-details outlined />-->
                  <Texting
                    id="activate-project-setEndDate-a36030d4"
                    @click.native="setEndDate"
                    class="pointer"
                    type="label1 Thirdly"
                    style="text-align: center; padding-top: 8px"
                    >Set to days left</Texting
                  >
                </div>
                <Images
                  id="activate-project-addDay-icon-ce83c38e"
                  @click.native="addDay"
                  name="Plus_icon.png"
                  class="pointer"
                />
              </v-col>
              <v-col
                cols="auto"
                v-if="
                  Number(totalDays) <= maxTotalDays && Number(totalDays) >= 1
                "
                sm="4"
                class="d-inline-flex pr-3 pr-sm-12"
                style="padding-left: 4px; padding-bottom: 0"
              >
                <Heading class="ml-auto" type="h1-4 MainDark"
                  >${{ price_day() }}</Heading
                >
                <div>
                  <div
                    v-if="pack_name() !== 'Short'"
                    class="ml-2 mt-1"
                    style="
                      width: 34px;
                      height: 1px;
                      background-color: #ffffff;
                      position: relative;
                      top: 4px;
                      z-index: 2;
                      left: 8px;
                    "
                  ></div>
                  <Texting
                    v-if="pack_name() !== 'Short'"
                    type="p2b mywhite"
                    class="px-3 ml-2"
                    style="
                      background-color: #9e33e0;
                      border-radius: 28px;
                      text-align: center;
                      line-height: 18px;
                      padding-top: 2px;
                      position: relative;
                      bottom: 5px;
                      z-index: 1;
                    "
                    >$23</Texting
                  >
                  <div
                    v-else
                    style="position: relative; bottom: 5px; height: 21px"
                  ></div>
                  <Texting class="pl-2" type="p2b MainDark">/day</Texting>
                </div>
              </v-col>
              <v-col v-else cols="auto" sm="4"></v-col>
            </v-row>
            <v-row v-else align="center" style="position: relative">
              <v-col
                v-if="subscribtion !== 'Only Trial'"
                class="d-flex"
                cols="auto"
                style="padding-left: 45px"
              >
                <Texting
                  class="d-flex"
                  type="p1bold MainDark"
                  style="opacity: 0.8"
                  >Billing Cycle</Texting
                >
                <v-tooltip
                  content-class="my-tooltip"
                  color="rgba(38,39,40,0.9)"
                  max-width="200px"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <span style="height: 33px" v-on="on">
                      <div
                        style="height: 33px; width: 33px"
                        class="justify-center d-flex align-center"
                      >
                        <Images
                          class="d-flex"
                          style="
                            position: relative;
                            bottom: 2px;
                            margin-left: 5px;
                          "
                          name="interrogative.svg"
                        />
                      </div>
                    </span>
                  </template>
                  <Texting type="p1n mywhite" style="text-align: center"
                    >Your next billing date is
                    {{
                      getBillingDate().month +
                      " " +
                      getBillingDate().day +
                      ", " +
                      getBillingDate().year +
                      "."
                    }}</Texting
                  >
                  <div class="arrow-tooltip" style="left: 77px"></div>
                </v-tooltip>
              </v-col>
              <v-col v-else cols="2" style="padding-left: 45px"></v-col>
              <v-col
                @click="subscribtions = !subscribtions"
                cols="auto"
                id="sub_menu_ind"
                class="ml-auto pointer"
                :style="{
                  paddingTop: compactHeight ? '30px' : '42px',
                  paddingBottom: compactHeight ? '0' : '18px',
                }"
                style="position: relative"
              >
                <div
                  class="d-flex align-center justify-center"
                  style="position: relative"
                >
                  <Heading
                    :type="
                      'h3-1 ' + 'color-' + `${subscribtion.split(' ').join('')}`
                    "
                    style="padding-right: 8px"
                    >{{ subscribtion }}</Heading
                  >
                  <Images
                    v-if="!subscribtions"
                    class="pointer"
                    height="6px"
                    name="indemand_menu_icon.png"
                  />
                  <Images
                    v-else
                    class="pointer"
                    height="6px"
                    name="indemand_menu_icon_active.png"
                  />
                </div>
                <Images
                  v-show="subscribtion === 'Weekly'"
                  style="position: relative; bottom: 12px"
                  name="indemand_bottom_line1.png"
                />
                <Images
                  v-show="subscribtion === 'Monthly'"
                  style="position: relative; bottom: 12px"
                  name="indemand_bottom_line2.png"
                />
                <Images
                  v-show="subscribtion === '3-Month'"
                  style="position: relative; bottom: 12px"
                  name="indemand_bottom_line3.png"
                />
                <Images
                  v-show="subscribtion === '6-Month'"
                  style="position: relative; bottom: 12px"
                  name="indemand_bottom_line4.png"
                />
                <Images
                  v-show="subscribtion === 'Only Trial'"
                  style="position: relative; bottom: 12px"
                  name="indemand_bottom_line.png"
                />
              </v-col>
              <transition name="slide-fade-menu" type="inherit">
                <div v-show="subscribtions" class="subscription-menu">
                  <v-list
                    id="subscribtions-list-4745c394"
                    style="
                      background: #f9f9f9;
                      box-shadow: 0 17px 75px rgba(0, 0, 0, 0.07),
                        0 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
                        0 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
                        0 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
                        0 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
                      border-radius: 8px;
                    "
                  >
                    <v-list-item
                      :id="'subscribtions-list-item-' + index"
                      v-for="(item, index) in items"
                      :key="index"
                      @click="
                        subscribtion = item.title;
                        subscribtions = false;
                      "
                      v-show="item.title !== subscribtion"
                    >
                      <v-list-item-title class="pointer">
                        <Texting type="p1 MainDark">{{ item.title }}</Texting>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </transition>
              <v-col
                cols="auto"
                :style="{ paddingBottom: compactHeight ? '0' : '12px' }"
                class="ml-auto"
              >
                <Heading type="h1-4 MainDark"
                  >${{
                    pack_name() === "Only Trial" ? "0" : price_day()
                  }}</Heading
                >
              </v-col>
              <v-col
                cols="auto"
                style="padding-left: 4px; margin-right: 30px"
                :style="{
                  paddingBottom:
                    pack_name() !== 'Only Trial' && pack_name() !== 'Weekly'
                      ? ''
                      : '0',
                }"
              >
                <div
                  v-if="
                    pack_name() !== 'Only Trial' && pack_name() !== 'Weekly'
                  "
                  style="
                    width: 42px;
                    height: 1px;
                    background-color: #ffffff;
                    position: relative;
                    top: 4px;
                    z-index: 2;
                    left: 7px;
                  "
                ></div>
                <Texting
                  v-if="
                    pack_name() !== 'Only Trial' && pack_name() !== 'Weekly'
                  "
                  type="p2b mywhite"
                  class="px-2"
                  style="
                    background-color: #9e33e0;
                    border-radius: 28px;
                    text-align: center;
                    line-height: 18px;
                    padding-top: 2px;
                    position: relative;
                    bottom: 5px;
                    z-index: 1;
                  "
                  >$20.9</Texting
                >
                <Texting type="p2b MainDark">/day</Texting>
              </v-col>
            </v-row>
            <v-row justify="center" class="activate-data">
              <v-col
                cols="7"
                class="py-0 price-section"
                :style="{ marginTop: compactHeight ? '15px' : '32px' }"
              >
                <v-row
                  v-if="pack_name() !== 'Only Trial'"
                  justify="space-between"
                  style="
                    background-color: #ffffff;
                    padding-top: 26px;
                    padding-bottom: 10px;
                  "
                >
                  <v-col cols="auto" class="py-0" style="padding-left: 32px">
                    <Texting type="p2 MainDark">Price for the period</Texting>
                  </v-col>
                  <v-col cols="auto" class="py-0" style="padding-right: 32px">
                    <Texting type="p3bold MainDark"
                      >${{ total_price() }}</Texting
                    >
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    (pack_name() !== 'Only Trial' && indemand) ||
                    (Number(totalDays) <= maxTotalDays &&
                      Number(totalDays) >= 1 &&
                      live)
                  "
                  justify="space-between"
                  style="background-color: #ffffff"
                  :style="{
                    paddingBottom:
                      user.usedFreeTrial && promo_valid !== 'true'
                        ? '26px'
                        : '10px',
                  }"
                >
                  <v-col cols="auto" class="py-0" style="padding-left: 32px">
                    <Texting type="p2 grey10">
                      {{ pack_name() }}
                      {{ live ? "Package" : "Subscription" }} Discount
                      <span
                        class="p1bold"
                        style="
                          background-color: #9e33e0;
                          border-radius: 4px;
                          padding: 5px 4px 2px 4px;
                          color: #ffffff;
                          margin-left: 6px;
                        "
                        >{{ discount() }}%</span
                      >
                    </Texting>
                  </v-col>
                  <v-col cols="auto" class="py-0" style="padding-right: 32px">
                    <Texting type="p3bold grey10"
                      >{{ price_discount() === 0 ? "" : "-" }}${{
                        price_discount()
                      }}</Texting
                    >
                  </v-col>
                </v-row>
                <v-row
                  v-if="!user.usedFreeTrial"
                  justify="space-between"
                  :style="{
                    paddingTop: pack_name() === 'Only Trial' ? '26px' : '',
                    paddingBottom:
                      promo_valid === 'true' && pack_name() !== 'Only Trial'
                        ? '10px'
                        : '26px',
                  }"
                  style="background-color: #ffffff"
                >
                  <v-col cols="auto" class="py-0" style="padding-left: 32px">
                    <Texting type="p2 grey10">3 Days for FREE!</Texting>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="py-0 d-flex"
                    style="padding-right: 32px"
                  >
                    <Texting class="d-flex" type="p3bold grey10"
                      >{{ live ? "-" : "" }}${{
                        live ? price_day() * 3 : "0"
                      }}</Texting
                    >
                  </v-col>
                </v-row>
                <v-row
                  v-if="promo_valid === 'true' && pack_name() !== 'Only Trial'"
                  justify="space-between"
                  style="background-color: #ffffff; padding-bottom: 26px"
                >
                  <v-col cols="auto" class="py-0" style="padding-left: 32px">
                    <Texting type="p2 grey10">
                      <b>{{ form.code }}</b> applied
                      <span
                        class="p1bold"
                        style="
                          background-color: #9e33e0;
                          border-radius: 4px;
                          padding: 5px 4px 2px 4px;
                          color: #ffffff;
                          margin-left: 6px;
                        "
                        >{{ promo_percent }}%</span
                      >
                    </Texting>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="py-0 d-flex"
                    style="padding-right: 32px"
                  >
                    <Texting class="d-flex" type="p3bold grey10"
                      >-${{ promoDiscount() }}</Texting
                    >
                  </v-col>
                </v-row>
                <v-row class="dotted-border" style="height: 6px; z-index: 100">
                  <Images name="activate_circle_border.png" />
                </v-row>
                <v-row
                  justify="space-between"
                  align="center"
                  style="
                    background-color: #f9f9f9;
                    padding-top: 23px;
                    padding-bottom: 22px;
                  "
                >
                  <v-col
                    cols="auto"
                    sm="7"
                    class="py-0 pr-0"
                    style="padding-left: 32px; max-width: 230px"
                  >
                    <Texting type="p2b MainDark">Total Amount</Texting>
                    <Texting
                      v-if="live || pack_name() === 'Only Trial'"
                      type="p1 MainDark"
                      >ends on
                      {{
                        getEndDate().month +
                        " " +
                        getEndDate().day +
                        ", " +
                        getEndDate().time +
                        " " +
                        getEndDate().country +
                        " " +
                        getEndDate().gmt
                      }}</Texting
                    >
                    <Texting v-else type="p2 MainDark"
                      >due every {{ total_days() }} days</Texting
                    >
                  </v-col>
                  <v-col
                    cols="auto"
                    sm="5"
                    class="py-0 pl-0"
                    style="padding-right: 32px"
                  >
                    <Heading type="h4 MainDark" style="text-align: right"
                      >${{
                        promo_valid === "true" && pack_name() !== "Only Trial"
                          ? priceWithPromo()
                          : price()
                      }}</Heading
                    >
                  </v-col>
                </v-row>
              </v-col>
              <div style="width: 100%"></div>
              <v-col
                v-if="
                  indemand &&
                  !user.usedFreeTrial &&
                  pack_name() !== 'Only Trial'
                "
                cols="auto"
                :style="{ paddingTop: compactHeight ? '12px' : '28px' }"
                style="padding-bottom: 0 !important"
              >
                <Texting
                  type="p1bold MainDark"
                  style="opacity: 0.8; text-align: center"
                >
                  Your paid subscription will start in 3 days:
                  <br />
                  on
                  {{
                    getStartDateIndemand().day_name +
                    ", " +
                    getStartDateIndemand().month +
                    " " +
                    getStartDateIndemand().day +
                    ", " +
                    getStartDateIndemand().time +
                    " | " +
                    getStartDateIndemand().country +
                    " " +
                    getStartDateIndemand().gmt
                  }}
                </Texting>
              </v-col>
              <v-col
                v-if="
                  indemand && user.usedFreeTrial && pack_name() !== 'Only Trial'
                "
                cols="auto"
                style="padding-top: 28px; padding-bottom: 0"
              >
                <Texting
                  type="p1bold MainDark"
                  style="opacity: 0.8; text-align: center"
                >
                  {{
                    getStartDateIndemand().day_name +
                    ", " +
                    getStartDateIndemand().month +
                    " " +
                    getStartDateIndemand().day +
                    ", " +
                    getStartDateIndemand().time +
                    "\xa0\xa0 | \xa0\xa0" +
                    getStartDateIndemand().country +
                    " " +
                    getStartDateIndemand().gmt
                  }}
                </Texting>
              </v-col>
              <div style="width: 100%"></div>
              <v-col
                cols="auto"
                :style="{
                  paddingTop: compactHeight ? '15px' : '28px',
                  paddingBottom: compactHeight ? '15px' : '39px',
                }"
                style="padding-bottom: 39px; padding-top: 28px"
              >
                <Texting type="p1 MainDark" style="opacity: 0.8">
                  By clicking “{{
                    pack_name() === "Only Trial" ? "Activate" : "Checkout"
                  }}” you agree to our
                  <span
                    id="activate-project-terms-8a00045a"
                    @click="toTerms"
                    style="color: #9e33e0; cursor: pointer"
                    >Terms</span
                  >
                  and
                  <span
                    @click="toPolicy"
                    style="color: #9e33e0; cursor: pointer"
                    >Data Policy.</span
                  >
                </Texting>
              </v-col>
            </v-row>
            <v-row justify="center" id="activate-bottom-section">
              <v-col
                cols="11"
                :style="{
                  paddingTop: compactHeight ? '15px' : '32px',
                  paddingBottom: compactHeight ? '0' : '10px',
                }"
              >
                <v-row align="center">
                  <v-col
                    v-if="
                      this.pack_name() !== 'Only Trial' &&
                      !(
                        live &&
                        !user.usedFreeTrial &&
                        Number(totalDays) <= 3 &&
                        Number(totalDays) >= 1
                      )
                    "
                    cols="auto"
                    :style="{
                      paddingTop: '0',
                      paddingBottom: !promo ? '19px' : '0',
                    }"
                    style="padding-left: 20px; padding-right: 0"
                  >
                    <Texting
                      id="add-promo-text-b2fb0ad0"
                      v-if="!promo"
                      type="p1 thirthly"
                      class="pointer"
                      @click.native="promo = !promo"
                      >Add Promo Code</Texting
                    >
                    <v-text-field
                      v-else
                      id="promo-input"
                      onfocus="this.placeholder = ''"
                      v-model.trim="form.code"
                      background-color="#FFFFFF"
                      :class="{
                        'error--text v-input--has-state':
                          this.promo_valid === 'false',
                        'success v-input--has-state':
                          this.promo_valid === 'true',
                      }"
                      style="max-width: 122px; max-height: 48px !important"
                      hide-details
                      dense
                      outlined
                    />
                    <div v-if="promo" style="height: 25px; padding-top: 6px">
                      <Texting
                        v-if="this.promo_valid === 'false'"
                        type="p1 redColor"
                        >{{ promo_err }}</Texting
                      >
                    </div>
                  </v-col>
                  <v-col
                    v-if="
                      promo &&
                      this.pack_name() !== 'Only Trial' &&
                      !(
                        live &&
                        !user.usedFreeTrial &&
                        Number(totalDays) <= 3 &&
                        Number(totalDays) >= 1
                      )
                    "
                    cols="auto"
                    style="
                      padding-left: 8px;
                      padding-bottom: 25px;
                      padding-top: 0;
                    "
                  >
                    <div
                      id="check-promo-button-0f58cc04"
                      @click="getCoupon"
                      class="pointer check-promo-button"
                    >
                      <Images name="arrow-right_promo.png" />
                    </div>
                    <!--<Button
                        :handleClick="getCoupon"
                        texting="plbold grey10"
                        type="secondary"
                        size="normal"

><Images name="arrow-right_promo.png"/></Button>-->
                  </v-col>
                  <v-col
                    cols="auto"
                    style="
                      padding-top: 0;
                      margin-left: auto;
                      padding-bottom: 17px;
                    "
                  >
                    <v-row>
                      <v-col cols="auto" class="pa-0">
                        <Button
                          id="purchase-button-3da94af2"
                          v-if="
                            this.pack_name() !== 'Only Trial' &&
                            !(
                              live &&
                              !user.usedFreeTrial &&
                              Number(totalDays) <= 3 &&
                              Number(totalDays) >= 1
                            )
                          "
                          :disabled="activateDisabled"
                          type="purchase"
                          b_type="submit"
                          :handleClick="purchase"
                        />
                        <Button
                          v-else
                          id="activate-project-button-5aa56a3c"
                          type="primary"
                          size="normal"
                          b_type="submit"
                          :disabled="activateDisabled"
                          :handleClick="purchase"
                          >activate</Button
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import myServices from "../services/resource";
import Button from "./Button.vue";
import Texting from "./Texting";
import Images from "./MyImages";
import { mapState } from "vuex";
import axios from "axios";
//import MySwitch from "../MySwitch/MySwitch";
import Heading from "./Heading";
import router from "../plugins/router";
//let stripe = Stripe("pk_test_LIfIPSV1DlAAMqShI6Jpn9oM00Vi6LUSea"); //eslint-disable-line

export default {
  components: {
    Heading,
    Images,
    Texting,
    Button,
  },
  props: {
    project: Object,
    button_size: {
      type: String,
      default: "small",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activateDisabled: false,
    live: false,
    indemand: false,
    maxTotalDays: null,
    phoneValidError: false,
    totalInputValid: false,
    phoneValid: false,
    sessionId: "",
    flag_opened: false,
    subscribtions: false,
    subscribtion: "3-Month",
    totalDays: "",
    promo: false,
    promo_percent: 0,
    compactHeight: false,
    promo_err: "",
    dialog: false,
    promo_valid: "",
    switch: false,
    daily: false,
    menu2: false,
    rules: {
      required: (value) => !!value || "",
      counter: (value) => Number(value) >= 1 || "",
      correct: (value) => {
        const pattern = /^[1-9]\d*$|^0$/;
        return pattern.test(value) || "";
      },
    },
    activate_texts: {
      header: "",
      subHeader: "",
      text: "",
    },
    items: [
      {
        title: "Weekly",
      },
      {
        title: "Monthly",
      },
      {
        title: "3-Month",
      },
      {
        title: "6-Month",
      },
    ],
    price: function () {
      if (this.live) {
        if (
          Number(this.totalDays) > this.maxTotalDays ||
          Number(this.totalDays) < 1
        )
          return 0;
        if (isNaN(this.totalDays)) return 0;
        if (!this.user.usedFreeTrial && this.total_days() > 3) {
          return ((this.total_days() - 3) * this.price_day()).toFixed(2);
        }
        if (this.user.usedFreeTrial) {
          return (this.total_days() * this.price_day()).toFixed(2);
        }
      }
      if (this.indemand) {
        if (this.pack_name() === "Only Trial") return "0.00";
        return (this.total_days() * this.price_day()).toFixed(2);
      }
      return 0;
    },
    priceWithPromo: function () {
      if (this.promo_valid === "true") {
        if (this.live) {
          if (!this.user.usedFreeTrial && this.total_days() > 3) {
            return (
              this.price() -
              (this.price() * this.promo_percent) / 100
            ).toFixed(2);
          }
          if (this.user.usedFreeTrial) {
            return (
              this.price() -
              (this.price() * this.promo_percent) / 100
            ).toFixed(2);
          }
        }
        if (this.indemand) {
          return (
            this.price() -
            (this.price() * this.promo_percent) / 100
          ).toFixed(2);
        }
      }
    },
    total_days: function () {
      if (this.live) {
        return this.totalDays;
      } else if (this.indemand) {
        if (this.pack_name() === "Only Trial") {
          return 3;
        }
        if (this.pack_name() === "Weekly") {
          return 7;
        }
        if (this.pack_name() === "Monthly") {
          return 30;
        }
        if (this.pack_name() === "3-Month") {
          return 90;
        }
        if (this.pack_name() === "6-Month") {
          return 180;
        }
      }
    },
    price_day: function () {
      if (this.live) {
        if (this.pack_name() === "Short") {
          return 23;
        } else if (this.pack_name() === "Medium") {
          return 21;
        } else if (this.pack_name() === "Long") {
          return 18;
        }
        return 0;
      } else if (this.indemand) {
        if (this.pack_name() === "Only Trial") {
          return 62.7;
        } else if (this.pack_name() === "Weekly") {
          return 20.9;
        } else if (this.pack_name() === "Monthly") {
          return 17.9;
        } else if (this.pack_name() === "3-Month") {
          return 10.9;
        } else if (this.pack_name() === "6-Month") {
          return 9.9;
        }
      }
    },
    total_price() {
      if (this.live) {
        if (
          Number(this.totalDays) > this.maxTotalDays ||
          Number(this.totalDays) < 1
        )
          return 0;
        if (isNaN(this.total_days())) return 0;
        return this.total_days() * 23;
      }
      if (this.indemand) {
        let price = this.total_days() * 20.9;
        return Number(price.toFixed(2));
      }
    },
    price_discount() {
      if (this.live) {
        return (23 - this.price_day()) * this.total_days();
      } else if (this.indemand) {
        let disc = (20.9 - this.price_day()) * this.total_days();
        return Number(disc.toFixed(2));
      }
    },
    discount() {
      if (this.live) {
        if (this.pack_name() === "Short") {
          return 0;
        } else if (this.pack_name() === "Medium") {
          return 9;
        } else if (this.pack_name() === "Long") {
          return 22;
        }
      }
      if (this.indemand) {
        if (this.pack_name() === "Weekly") {
          return 0;
        } else if (this.pack_name() === "Monthly") {
          return 14;
        } else if (this.pack_name() === "3-Month") {
          return 48;
        } else if (this.pack_name() === "6-Month") {
          return 53;
        }
      }
    },
    form: {
      packageTotalDays: 1,
      packageStartDate: new Date(),
      packageEndDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      packageTotalPrice: null,
      packageType: "",
      resiveCriticalAlerts: false,
      phoneNumber: "",
      code: "",
    },
  }),
  computed: {
    ...mapState({
      projects: "projects",
      user: "user",
    }),
  },
  watch: {
    totalDays: function (val) {
      if (Number(val) > this.maxTotalDays) {
        myServices.showErrorMessage(
          this,
          {},
          "Selected days must be equal or less than the campaign remaining period",
        );
        this.$nextTick(function () {
          this.totalDays = this.maxTotalDays.toString();
        });
      }
    },
    dialog: function (val) {
      if (val) {
        this.subscribtions = false;
        this.form.code = "";
        this.promo = false;
        this.promo_err = "";
        this.promo_valid = "";
        if (this.project.campaignStatus === "LIVE") {
          this.live = true;
          this.setEndDate();
        }
        if (this.project.campaignStatus === "INDEMAND") {
          this.indemand = true;
          this.subscribtion = "3-Month";
        }
      }
      if (!this.dialog) {
        if (this.$route.query.activate) this.$router.replace({ query: null });
        this.$emit("close");
      }
    },
    active(val) {
      if (val) {
        this.dialog = true;
      }
    },
  },
  beforeUpdate() {
    this.$nextTick(function () {
      let textbox = document.getElementById(
        "dates-" + this.project.projectTitle,
      );
      if (textbox) {
        let inputFilter = function (value) {
          return /^\d*$/.test(value);
        };
        [
          "input",
          "keydown",
          "keyup",
          "mousedown",
          "mouseup",
          "select",
          "contextmenu",
          "drop",
        ].forEach(function (event) {
          textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
              this.oldValue = this.value;
              this.oldSelectionStart = this.selectionStart;
              this.oldSelectionEnd = this.selectionEnd;
              if (this.value !== "") this.value = Number(this.value);
              // eslint-disable-next-line no-prototype-builtins
            } else if (this.hasOwnProperty("oldValue")) {
              if (this.oldValue !== "") {
                this.value = Number(this.oldValue);
              } else {
                this.value = this.oldValue;
              }
              this.setSelectionRange(
                this.oldSelectionStart,
                this.oldSelectionEnd,
              );
            } else {
              this.value = "";
            }
          });
        });
      }
    });
  },
  updated() {
    if (document.getElementById("dates-" + this.project.projectTitle)) {
      if (this.dialog) {
        const input = document.getElementById(
          "dates-" + this.project.projectTitle,
        );
        input.value = this.totalDays;
      }
    }
    if (
      this.live &&
      (this.totalDays === "0" ||
        this.totalDays === "" ||
        this.totalDays === 0 ||
        Number(this.totalDays) < 1)
    ) {
      this.totalInputValid = false;
    } else {
      this.totalInputValid = true;
    }
  },
  async mounted() {
    if (window.innerHeight < 840) {
      this.compactHeight = true;
    }
    if (this.project.campaignStatus === "LIVE") this.live = true;
    if (this.project.campaignStatus === "INDEMAND") this.indemand = true;
    if (
      this.project.campaignStatus !== "LIVE" &&
      this.project.campaignStatus !== "INDEMAND"
    ) {
      myServices.showErrorMessage(this, {});
      this.close();
    }
    if (this.live) this.setEndDate();
    // if (this.active) {
    //     this.dialog = true;
    // }
    if (!this.user.phoneNumber) this.form.phoneNumber = "";
    else this.form.phoneNumber = this.user.phoneNumber;

    if (!this.user.usedFreeTrial) {
      this.items = [
        {
          title: "Only Trial",
        },
        {
          title: "Weekly",
        },
        {
          title: "Monthly",
        },
        {
          title: "3-Month",
        },
        {
          title: "6-Month",
        },
      ];
    }
    // let stripeJs = document.createElement("script");
    // stripeJs.setAttribute("src", "https://js.stripe.com/v3/");
    // document.head.appendChild(stripeJs);
  },
  methods: {
    onTotalInput(e) {
      let inputFilter = function (value) {
        return /^\d*$/.test(value);
      };
      if (inputFilter(e.target.value) && e.target.value !== "") {
        this.totalDays = Number(e.target.value).toString();
      }
      if (e.target.value === "") this.totalDays = "";
    },
    onClickOutside(event) {
      if (this.subscribtions) {
        const myMenu = document.getElementById("sub_menu_ind");
        let targetElement = event.target;
        do {
          if (targetElement == myMenu) {
            return;
          }
          targetElement = targetElement.parentNode;
        } while (targetElement);
        this.subscribtions = false;
      }
    },
    setEndDate() {
      const date1 = new Date();
      const date2 = new Date(this.project.campaignEndDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      this.maxTotalDays = diffDays;
      this.totalDays = diffDays.toString();
    },
    toTerms() {
      let routeData = this.$router.resolve({
        name: "TermsPage",
      });
      window.open(routeData.href + "?blank=true", "_blank");
    },
    toPolicy() {
      let routeData = this.$router.resolve({
        name: "Policy",
      });
      window.open(routeData.href + "?blank=true", "_blank");
    },
    promoDiscount() {
      if (this.promo_percent > 0) {
        //let disc = (this.price() * this.promo_percent) / 100;
        return (this.price() - this.priceWithPromo()).toFixed(2);
      }
    },
    getStartDateIndemand() {
      let d = new Date();
      if (!this.user.usedFreeTrial) {
        d.setDate(d.getDate() + 3);
      }
      let m = d.toString();
      let day_name = m.substr(0, 3);
      let month = m.substr(4, 3);
      let day = m.substr(8, 2);
      let time = m.substr(16, 5);
      let gmt = m.substr(25, 6);
      if (gmt.substr(4, 1) === "0") {
        gmt = gmt.substr(0, 4) + gmt.substr(5, 1);
      }
      gmt = "(" + gmt + ")";
      let country = m.substring(m.lastIndexOf("(") + 1, m.lastIndexOf(")"));
      return {
        day_name: day_name,
        month: month,
        day: day,
        time: time,
        country: country,
        gmt: gmt,
      };
    },
    getBillingDate() {
      if (this.live) return;
      let d = new Date();
      if (this.pack_name() === "Only Trial") {
        d.setDate(d.getDate() + 3);
      }
      if (this.user.usedFreeTrial) {
        d.setDate(d.getDate() + Number(this.total_days()));
      } else if (this.pack_name() !== "Only Trial") {
        d.setDate(d.getDate() + Number(this.total_days()) + 3);
      }

      let m = d.toString();
      let month = m.substr(4, 3);
      let day = m.substr(8, 2);
      let year = m.substr(11, 4);
      return {
        month: month,
        day: day,
        year: year,
      };
    },
    getEndDate() {
      let d = new Date();
      if (Number(this.totalDays) === this.maxTotalDays) {
        d = new Date(this.project.campaignEndDate);
      } else {
        d.setDate(d.getDate() + Number(this.total_days()));
      }
      let m = d.toString();
      let month = m.substr(4, 3);
      let day = m.substr(8, 2);
      let time = m.substr(16, 5);
      let gmt = m.substr(25, 6);
      if (gmt.substr(4, 1) === "0") {
        gmt = gmt.substr(0, 4) + gmt.substr(5, 1);
      }
      gmt = "(" + gmt + ")";
      let country = m.substring(m.lastIndexOf("(") + 1, m.lastIndexOf(")"));
      return {
        month: month,
        day: day,
        time: time,
        country: country,
        gmt: gmt,
      };
    },
    addDay() {
      if (this.totalDays.match(/^[1-9]\d*$|^0$/)) {
        if (Number(this.totalDays) === this.maxTotalDays) return;
        this.totalDays = (Number(this.totalDays) + 1).toString();
      }
    },
    removeDay() {
      if (
        this.totalDays.match(/^[1-9]\d*$|^0$/) &&
        Number(this.totalDays) >= 2
      ) {
        this.totalDays = (Number(this.totalDays) - 1).toString();
      }
    },
    // defaultRoute() {
    //     if (this.openactivate) {
    //         this.dialog = false;
    //         router.push({
    //             name: "user"
    //         });
    //     } else {
    //         this.dialog = false;
    //     }
    //     setTimeout(() => {
    //         this.switch = false;
    //         this.phoneValidError = false;
    //         this.phoneValid = true;
    //     }, 500);
    // },
    onValidate(obj) {
      this.phoneValid = obj.isValid;
      if (obj.isValid) {
        this.phoneValidError = false;
        this.phoneValid = true;
      }
    },
    purchase: function () {
      if (Number(this.totalDays) > this.maxTotalDays && this.live) return;
      if (
        this.pack_name() !== "Only Trial" &&
        !(
          this.live &&
          !this.user.usedFreeTrial &&
          Number(this.totalDays) <= 3 &&
          Number(this.totalDays) >= 1
        )
      ) {
        myServices.myAnalyticsEvents(
          this,
          "Activate campaign",
          "Checkout button click",
        );
        /*this.$ga.event('Activate campaign', 'Checkout button click');
                this.$mixpanel.track('Activate campaign', {
                  action: 'Checkout button click',
                });*/
      } else {
        myServices.myAnalyticsEvents(
          this,
          "Activate campaign",
          "Activate-purchase button click",
        );
        /*this.$ga.event('Activate campaign', 'Activate-purchase button click');
                this.$mixpanel.track('Activate campaign', {
                  action: 'Activate-purchase button click',
                });*/
      }
      this.form.packageTotalDays = this.total_days();
      this.form.packageTotalPrice = this.price();
      this.form.packageType = this.getPackageType();
      if (
        this.indemand &&
        !this.user.usedFreeTrial &&
        this.pack_name() !== "Only Trial"
      ) {
        this.form.packageEndDate = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 * (this.total_days() + 3),
        );
      } else {
        if (this.live && Number(this.totalDays) === this.maxTotalDays) {
          this.form.packageEndDate = new Date(this.project.campaignEndDate);
          return;
        }
        this.form.packageEndDate = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 * this.total_days(),
        );
      }
    },
    getPackageType: function () {
      if (this.indemand) {
        if (this.subscribtion === "Weekly") {
          return "WEEKLY";
        }
        if (this.subscribtion === "Monthly") {
          return "MONTHLY";
        }
        if (this.subscribtion === "3-Month") {
          return "THREE_MONTH";
        }
        if (this.subscribtion === "6-Month") {
          return "SIX_MONTH";
        }
      }
      if (this.live) {
        return "ONCE";
      }
    },
    pack_name: function () {
      this.getPackageType();
      if (this.live) {
        if (this.total_days() <= 15 && this.total_days() >= 1) {
          return "Short";
        } else if (this.total_days() <= 35 && this.total_days() >= 16) {
          return "Medium";
        } else if (this.total_days() >= 36) {
          return "Long";
        }
      }
      if (this.indemand) {
        return this.subscribtion;
        // if (this.subscribtion === "Only Trial") {
        //     return this.subscribtion;
        // }
        // if (this.subscribtion === "Weekly") {
        //     return this.subscribtion;
        // }
        // if (this.subscribtion === "Monthly") {
        //     return this.subscribtion;
        // }
        // if (this.subscribtion === "3-Month") {
        //     return this.subscribtion;
        // }
        // if (this.subscribtion === "6-Month") {
        //     return this.subscribtion;
        // }
      }
    },
    open: function () {
      this.dialog = true;
      if (!this.user.phoneNumber && this.form.resiveCriticalAlerts) {
        this.form.phoneNumber = "";
      } else this.form.phoneNumber = this.user.phoneNumber;
    },
    close: function () {
      this.phoneValidError = false;
      this.phoneValid = true;
      this.dialog = false;
      this.form.resiveCriticalAlerts = false;
    },
    async getCoupon() {
      this.promo_percent = 0;
      this.promo_valid = "";
      let data = {
        code: this.form.code,
      };
      axios
        .post(`/api/coupon/getCoupon`, data)
        .then((res) => {
          const amount = res.data.data.amount;
          this.promo_percent = amount;
          this.promo_valid = "true";
        })
        .catch((error) => {
          let message = "Oops, something went wrong";
          this.promo_valid = "false";
          if (error.response && error.response.data) {
            message = error.response.data.message;
          }
          this.promo_err = message;
        });
    },
    onActivate() {
      this.activateDisabled = true;
      if (
        this.promo &&
        this.form.code &&
        this.form.code !== "" &&
        this.promo_err === "" &&
        this.promo_valid !== "true"
      ) {
        this.getCoupon();
        this.activateDisabled = false;
        return;
      }
      //if(this.live && (this.totalDays === '0' || this.totalDays === '' || this.totalDays === 0) || Number(this.totalDays) < 1) return;
      // if (
      //     !this.phoneValid &&
      //     this.form.resiveCriticalAlerts &&
      //     !this.user.phoneNumber
      // ) {
      //     this.phoneValidError = true;
      //     return;
      // }
      if (
        !this.user.usedFreeTrial &&
        Number(this.total_days()) <= 3 &&
        Number(this.total_days()) >= 1 &&
        !this.$props.project.innerProject
      ) {
        axios
          .post(`/api/package/usingFree/${this.$props.project.projectId}`)
          .then(() => {
            this.activateDisabled = false;
            this.$store.dispatch("getProjects");
            this.close();
            this.$router.replace({
              name: "user",
              query: {
                activated: "true",
                projectid: this.project.projectId,
              },
            });
          });
      } else if (
        this.$props.project.innerProject === true &&
        Number(this.total_days()) >= 1
      ) {
        axios
          .post(
            `/api/package/usingFree/${this.$props.project.projectId}`,
            this.form,
          )
          .then(() => {
            this.activateDisabled = false;
            this.$store.dispatch("getProjects");
            this.close();
            router.push({
              name: "user",
              query: {
                activated: "true",
                projectid: this.project.projectId,
              },
            });
          });
      } else {
        let data = {
          packageType: this.form.packageType,
          packageEndDate: this.form.packageEndDate,
          code: this.form.code,
        };

        axios
          .post(`/api/package/add/${this.$props.project.projectId}`, data)
          .then((res) => {
            this.$nextTick(() => (this.activateDisabled = false));
            this.sessionId = res.data.data.session;
            this.close();

            let stripe;
            if (process.env.NODE_ENV == "production") {
                            stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY_PROD); //eslint-disable-line
            } else if (process.env.NODE_ENV == "development") {
                            stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY_DEV); //eslint-disable-line
            }
            stripe
              .redirectToCheckout({ sessionId: this.sessionId.id })
              .then(() => {});
          })
          .catch((error) => {
            this.open();
            myServices.showErrorMessage(this, error);
            this.activateDisabled = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.dollar-icon {
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  position: relative;
  top: -13px;
  right: -6px;
}

.v-application .success {
  caret-color: #7fcf2d !important;
  color: #7fcf2d !important;
}

@media screen and (max-width: 635px) {
  .tel-cont {
    margin-top: 0 !important;
  }
}

.subscription-menu {
  min-width: 160px;
  position: absolute;
  top: 74px;
  left: 255px;
  background: #f9f9f9;
  box-shadow: 0 17px 75px rgba(0, 0, 0, 0.07),
    0 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
  border-radius: 8px;
}

.slide-fade-menu-enter-active,
.slide-fade-menu-leave-active {
  transition: opacity 0.1s;
}
.live-dates-input {
  padding-right: 45px;
  background-image: url(https://res.cloudinary.com/dyf7aejih/image/upload/v1631261637/images/days_lz5f4b.svg);
  background-repeat: no-repeat;
  background-position: 57px 22px;
}
.price-section {
  max-width: 427px;
  box-shadow: 0px 17px 75px rgba(0, 0, 0, 0.07),
    0px 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0px 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0px 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0px 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
}
</style>
