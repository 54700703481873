<template>
  <div>
    <v-dialog
      v-if="dialog"
      id="after-payment-confirmation-modal-8139189a"
      overlay-opacity="0.4"
      persistent
      v-model="dialog"
      max-width="657px"
      min-wi
    >
      <v-card
        id="after-payment-confirmation-card-928fb126"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          z-index: inherit;
        "
      >
        <v-container>
          <v-row align="center" style="border-bottom: 1px solid #f4f4f4">
            <v-col cols="1"></v-col>
            <v-col
              cols="auto"
              style="padding-left: 0; padding-top: 17px; padding-bottom: 22px"
            >
              <Texting type="p1 primColor">{{
                my_project.projectTitle
              }}</Texting>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="10">
              <v-row justify="center">
                <v-col cols="auto" style="padding-top: 56px">
                  <Images name="confirmations.svg" />
                </v-col>
                <div style="width: 100%"></div>
                <v-col
                  cols="auto"
                  style="padding-top: 27px; padding-bottom: 52px"
                >
                  <Texting type="h3 dark-asphalt">Activation Complete!</Texting>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col
                  cols="auto"
                  style="padding-right: 0; padding-bottom: 6px"
                >
                  <Button
                    id="after-payment-confirmation-next-button-33a4e1ac"
                    type="secondary"
                    size="normal"
                    texting="p3bold grey10"
                    :handleClick="closeModal"
                    >next</Button
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <CampaignLoading :active="loading" @loading-closed="onLoadingClosed" />
    <AfterPaymentModal :active="after_payment_modal" :project="my_project" />
  </div>
</template>

<script>
import Button from "./Button.vue";
import Texting from "./Texting";
import Images from "./MyImages";
import { mapState } from "vuex";
import CampaignLoading from "./CampaignLoading";
import AfterPaymentModal from "./AfterPaymentModal";
import myServices from "@/services/resource";
export default {
  components: {
    AfterPaymentModal,
    CampaignLoading,
    Images,
    Texting,
    Button,
  },
  props: {
    project_activated: Boolean,
  },
  data: () => ({
    dialog: false,
    loading: false,
    after_payment_modal: false,
    my_project: {},
  }),
  computed: {
    ...mapState({
      projects: "projects",
    }),
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.$store.dispatch("getProjects");
        setTimeout(() => {
          this.my_project = this.projects.filter(
            (pr) =>
              pr.projectId === this.$route.query.projectid &&
              pr.inArchive === false,
          )[0];
          if (
            this.my_project.projectState === "hasPerks" ||
            this.my_project.projectState === "hasPerksAndRefData"
          ) {
            this.after_payment_modal = true;
          } else {
            this.loading = true;
          }
        }, 1000);

        /*this.$store.dispatch("getProjects").then(() => {
                  this.my_project = this.projects.filter(
                    pr =>
                      pr.projectId === this.$route.query.projectid &&
                      pr.inArchive === false
                  )[0];

                });*/
      } else {
        localStorage.setItem("after_payment_confirmation", "true");
        localStorage.setItem(
          "after_payment_confirmation_canceled",
          window.location.href,
        );
        this.$confetti.start({
          defaultType: "rect",
          defaultDropRate: 10,
        });
        setTimeout(() => {
          this.$confetti.stop();
        }, 3500);
      }
    },
  },
  mounted() {
    this.dialog = this.project_activated;
    this.my_project = this.projects.filter(
      (pr) =>
        pr.projectId === this.$route.query.projectid && pr.inArchive === false,
    )[0];
  },
  beforeDestroy() {
    this.$store.dispatch("getProjects");
    setTimeout(() => {
      this.my_project = this.projects.filter(
        (pr) =>
          pr.projectId === this.$route.query.projectid &&
          pr.inArchive === false,
      )[0];
      if (
        this.my_project.projectState !== "" &&
        this.my_project.projectState !== null
      ) {
        localStorage.setItem("after_payment_confirmation_canceled", "");
      }
    }, 1000);
  },
  methods: {
    closeModal() {
      myServices.myAnalyticsEvents(
        this,
        "Activation - complete",
        "activation-complete-next button click",
      );
      this.dialog = false;
      this.$analytics.fbq.event("Purchase", {
        value: this.my_project.packagePrice / this.my_project.packageTotalDays,
      });
    },
    onLoadingClosed: function () {
      this.after_payment_modal = true;
      if (this.my_project.platform === "kickstarter") {
        this.$emit("waiting-for-ksdaily");
      }
    },
    close: function () {
      this.dialog = false;
    },
  },
};
</script>
