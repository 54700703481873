<template>
  <div>
    <v-dialog
      id="add-campaign-confirmation-modal-9e1b4cb8"
      v-if="dialog"
      overlay-opacity="0.1"
      persistent
      v-model="dialog"
      max-width="657px"
      min-wi
    >
      <v-card
        id="add-campaign-confirmation-card-aca3529e"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          z-index: inherit;
        "
      >
        <v-container>
          <v-row justify="end">
            <v-col cols="auto" style="padding-top: 8px; padding-right: 20px">
              <Images
                id="add-campaign-confirmation-close-icon-2bf16fe0"
                name="close.svg"
                class="pointer"
                v-on:click.native="close"
              />
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="10">
              <v-row justify="center">
                <v-col cols="auto" style="padding-top: 15px">
                  <Images name="confirmations.svg" />
                </v-col>
                <div style="width: 100%"></div>
                <v-col
                  cols="auto"
                  style="padding-top: 20px; padding-bottom: 2px"
                >
                  <Texting type="h3 dark-asphalt">Congratulations!</Texting>
                </v-col>
                <div style="width: 100%"></div>
                <v-col
                  cols="auto"
                  style="padding-bottom: 49px; text-align: center"
                >
                  <Texting v-if="!user.usedFreeTrial" type="p3 dark-asphalt"
                    >Activate your campaign and enjoy 3 days for free.</Texting
                  >
                  <Texting v-else type="p3 dark-asphalt"
                    >Activate your campaign and enjoy the automation of
                    rewards.</Texting
                  >
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col
                  cols="auto"
                  style="padding-right: 0; padding-bottom: 6px"
                >
                  <Button
                    id="add-campaign-confirmation-later-button-4553a9f8"
                    type="cancel"
                    size="normal-min"
                    texting="p2b grey2"
                    :handleClick="close"
                    >later</Button
                  >
                </v-col>
                <v-col
                  cols="auto"
                  style="padding-right: 0; padding-bottom: 6px"
                >
                  <ActivateProject
                    v-if="oneProject.projectTitle === project_title"
                    @close="onClose"
                    @click.native="stopConfetti"
                    button_size="normal-min"
                    :project="oneProject"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import myServices from "../services/resource";
import Button from "./Button.vue";
import Texting from "./Texting";
import Images from "./MyImages";
import ActivateProject from "./ActivateProject";
import { mapActions, mapState } from "vuex";
export default {
  components: { ActivateProject, Images, Texting, Button },
  props: {
    project_title: String,
  },
  data: () => ({
    dialog: false,
    project: {},
  }),
  computed: {
    ...mapState({
      projects: "projects",
      oneProject: "oneProject",
      user: "user",
    }),
  },
  watch: {
    project_title: function (val) {
      if (val !== "") {
        if (!this.oneProject) this.getOneProject(val);
      }
    },
    dialog: function (val) {
      if (val) {
        if (this.project_title !== "") {
          this.getOneProject(this.project_title);
        }
        this.$confetti.start({
          defaultType: "rect",
          defaultDropRate: 10,
        });
        setTimeout(() => {
          this.$confetti.stop();
        }, 3500);
      } else {
        this.$emit("closed");
      }
    },
  },
  /*mounted() {
    if (
        !this.dialog &&
        localStorage.getItem("add_project_confirmation") === "true"
    ) {
      this.dialog = true;
    }
  },*/
  methods: {
    open() {
      this.dialog = true;
    },
    onClose() {
      this.dialog = false;
    },
    stopConfetti: function () {
      this.$confetti.stop();
      myServices.myAnalyticsEvents(
        this,
        "Add campaign",
        'Click to "Activate campaign" button',
      );
      /*this.$ga.event('Add campaign', 'Click to "Activate campaign" button');
      this.$mixpanel.track('Add campaign', {
        action: 'Click to "Activate campaign" button',
      });*/
    },
    close: function () {
      this.dialog = false;
    },
    ...mapActions(["getOneProject"]),
  },
};
</script>
