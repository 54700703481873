var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.draftProjects.length > 0)?_c('table',[_c('tr',{},[_c('th',{staticStyle:{"padding-left":"19px"},attrs:{"width":"4%"}}),_c('th',{staticStyle:{"padding-left":"10px"},attrs:{"width":"24%"}}),_c('th',{attrs:{"width":"20%"}}),_c('th',{attrs:{"width":"20%"}},[_c('div',{staticClass:"d-flex justify-center mb-3"},[(!_vm.user.usedFreeTrial && _vm.notInArchive.length > 0)?_c('div',{staticStyle:{"text-align":"center"}},[_c('Texting',{attrs:{"type":"p1black Fivethly"}},[_vm._v("Get 3 Days for Free!")]),_c('Texting',{attrs:{"type":"label1 Fivethly"}},[_vm._v("on your first campaign")])],1):_vm._e()])]),_c('th',{attrs:{"width":"33%"}})]),_vm._l((_vm.draftProjects),function(draftProject,i){return _c('tr',{key:i,staticClass:"pr",attrs:{"id":'draftProject-' + i},on:{"click":function($event){return _vm.projectClicked(draftProject, $event)}}},[_c('td',[_c('a',{attrs:{"href":draftProject.projectUrl}},[_c('Images',{staticClass:"pointer",staticStyle:{"display":"block","margin":"0 auto"},attrs:{"name":draftProject.projectUrl.includes('indiegogo')
              ? 'indiegogo.svg'
              : 'kickstarter.svg',"align":"middle"}})],1)]),_c('td',{staticClass:"d-inline-flex align-center",staticStyle:{"padding-left":"10px","max-width":"500px","height":"100%"}},[_c('div',{staticClass:"d-md-inline-flex d-sm-none d-none"},[_c('Texting',{attrs:{"type":"p1 primColor"}},[_vm._v(_vm._s(draftProject.projectTitle.length > 50 ? draftProject.projectTitle.slice(0, 20) + "..." : draftProject.projectTitle))])],1),_c('div',{staticClass:"d-md-none d-none d-sm-inline-flex"},[_c('Texting',{attrs:{"type":"p1 primColor"}},[_vm._v(" "+_vm._s(draftProject.projectTitle.slice(0, 20) + "...")+" ")])],1),_c('div',{staticClass:"d-md-none d-inline-flex d-sm-none"},[_c('Texting',{attrs:{"type":"p1 primColor"}},[_vm._v(" "+_vm._s(draftProject.projectTitle.slice(0, 6) + "...")+" ")])],1),_c('div',{staticStyle:{"padding":"2px 10px","border":"1px solid #a7a0b8","border-radius":"50px","display":"inline-flex","margin-left":"15px"}},[_c('Texting',{attrs:{"type":"p1bold package-4-ind-color"}},[_vm._v(_vm._s(draftProject.status === "CHECKING_LAUNCHING_SOON" || draftProject.status === "LAUNCHING_SOON" ? "Launching Soon" : "Draft"))])],1),(
          i !== 0 &&
          draftProject.status !== 'CHECKING_LAUNCHING_SOON' &&
          draftProject.status !== 'LAUNCHING_SOON'
        )?_c('div',{staticClass:"d-inline-flex ml-6"},[_c('v-tooltip',{attrs:{"content-class":"my-tooltip","color":"rgba(38,39,40,0.9)","max-width":"300px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center"},on),[_c('div',{staticClass:"contain-error"},[_c('div',{staticClass:"d-flex error-outline"},[_c('Images',{attrs:{"name":"error_outline.svg"}})],1)])])]}}],null,true)},[_c('Texting',{staticClass:"pt-2",attrs:{"type":"p1n mywhite"}},[_vm._v(_vm._s(_vm.getTooltipText(draftProject)))]),_c('div',{staticClass:"arrow-tooltip",staticStyle:{"left":"125px"}})],1)],1):_vm._e()]),_c('td',{staticStyle:{"opacity":"0.99"}},_vm._l((_vm.draftTimer),function(currentDraftTimer,i){return _c('div',{key:i,staticClass:"d-flex pr-2"},[(_vm.showDraftMinutes(currentDraftTimer, draftProject))?_c('Heading',{attrs:{"type":"h2-5 MainDark"}},[_vm._v(_vm._s(_vm.draftMinutes(currentDraftTimer.countDown)))]):_vm._e(),(_vm.showDraftMinutes(currentDraftTimer, draftProject))?_c('Texting',{staticStyle:{"padding-top":"14px","padding-left":"4px","padding-right":"12px"},attrs:{"type":"p4-1-3 MainDark"}},[_vm._v("m")]):_vm._e(),(_vm.showDraftSeconds(currentDraftTimer, draftProject))?_c('Heading',{attrs:{"type":"h2-5 MainDark"}},[_vm._v(_vm._s(_vm.draftSeconds(currentDraftTimer.countDown)))]):_vm._e(),(_vm.showDraftSeconds(currentDraftTimer, draftProject))?_c('Texting',{staticStyle:{"padding-top":"14px","padding-left":"4px"},attrs:{"type":"p4-1-3 MainDark"}},[_vm._v("s")]):_vm._e()],1)}),0),_c('td',{staticClass:"justify-center",staticStyle:{"vertical-align":"middle","display":"flex"},style:({
        paddingTop:
          draftProject.status !== 'LAUNCHING_SOON' &&
          draftProject.status !== 'CHECKING_LAUNCHING_SOON'
            ? '24px'
            : '44px',
      })},[(
          draftProject.status !== 'LAUNCHING_SOON' &&
          draftProject.status !== 'CHECKING_LAUNCHING_SOON'
        )?_c('Button',{staticStyle:{"display":"inline-flex"},style:({
          cursor:
            _vm.checkingDrafts.includes(draftProject.draftProjectId) &&
            draftProject.status === 'FULL_EDITING_RIGHTS_NOT_GIVEN'
              ? 'default'
              : 'pointer',
          backgroundColor:
            _vm.checkingDrafts.includes(draftProject.draftProjectId) &&
            draftProject.status === 'FULL_EDITING_RIGHTS_NOT_GIVEN'
              ? '#f4f4f4'
              : '',
        }),attrs:{"handleClick":function () {},"type":"secondary","size":"small","texting":"p1bold grey10","disabled":_vm.checkingDrafts.includes(draftProject.draftProjectId) &&
          draftProject.status === 'FULL_EDITING_RIGHTS_NOT_GIVEN'},nativeOn:{"click":function($event){return _vm.checkOrInvite(draftProject)}}},[_c('span',{staticStyle:{"text-transform":"none","white-space":"nowrap"}},[_vm._v(_vm._s(draftProject.status === "FULL_EDITING_RIGHTS_NOT_GIVEN" ? "Check Now" : "Invite us as a Team Member"))])]):_vm._e(),(
          _vm.checkingDrafts.includes(draftProject.draftProjectId) &&
          draftProject.status === 'FULL_EDITING_RIGHTS_NOT_GIVEN'
        )?_c('v-progress-circular',{staticStyle:{"margin-bottom":"3px","margin-left":"20px","display":"inline-flex"},attrs:{"width":3,"color":"#9E33E0","size":"30","indeterminate":""}}):_vm._e()],1),_c('td',{staticStyle:{"padding-right":"20px !important"}},[(
          draftProject.status !== 'LAUNCHING_SOON' &&
          draftProject.status !== 'CHECKING_LAUNCHING_SOON'
        )?_c('Texting',{staticClass:"show-instructions pointer float-end mr-10",staticStyle:{"opacity":"0.7","max-width":"177px"},attrs:{"type":"p2 MainDark"},nativeOn:{"click":function($event){return _vm.showInstructionsClick(draftProject)}}},[_vm._v("Show instructions again")]):_c('div',{staticClass:"d-inline-flex",staticStyle:{"padding-top":"6px"}},[_c('Images',{attrs:{"name":"error_outline.svg"}}),_c('Texting',{staticClass:"ml-2 mt-1",attrs:{"type":"foralert MainDark"}},[_vm._v("Come back and activate after you launch your campaign on "+_vm._s(draftProject.projectUrl.includes("indiegogo") ? "Indiegogo" : "Kickstarter")+".")])],1)],1)])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }