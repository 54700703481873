<template>
  <div>
    <v-dialog
      v-if="dialog"
      id="top-up-summary-modal-4a536a22"
      persistent
      overlay-opacity="0.95"
      v-model="dialog"
      max-width="657px"
      min-wi
    >
      <v-card
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          z-index: inherit;
        "
        id="after-payment-card-5879c754"
      >
        <v-container>
          <v-row justify="end">
            <v-col cols="auto" style="padding-top: 8px; padding-right: 20px">
              <Images name="close.svg" class="pointer" @click.native="close" />
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="10" style="padding-top: 0; padding-bottom: 6px">
              <v-row justify="center">
                <v-col cols="auto" style="padding-top: 0">
                  <Images
                    name="https://res.cloudinary.com/dyf7aejih/image/upload/v1631085041/images/email_icon_df5xq6.svg"
                  />
                </v-col>
              </v-row>
              <v-row justify="start" style="padding-left: 20px">
                <v-col cols="auto" style="padding-top: 23px">
                  <Heading type="h2 grey2">Change Email Frequency</Heading>
                </v-col>
                <div style="width: 100%"></div>
                <v-col cols="auto" style="padding-top: 11px">
                  <Texting type="p2 grey18"
                    >How often do you want to receive email
                    notifications?</Texting
                  >
                </v-col>
                <div style="width: 100%"></div>
                <v-col
                  cols="auto"
                  style="padding-top: 30px; padding-bottom: 28px"
                >
                  <label class="radio-container">
                    <Texting type="p2 grey18" style="line-height: 20px"
                      >Every time my perks are topped up</Texting
                    >
                    <input
                      type="radio"
                      value="EVERY_TOP_UP"
                      v-model="checked"
                      checked="checked"
                      name="radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container">
                    <Texting type="p2 grey18" style="line-height: 20px"
                      >Get a summary at the end of the day</Texting
                    >
                    <input
                      type="radio"
                      v-model="checked"
                      value="DAILY_TOP_UP_SUMMARY"
                      name="radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container">
                    <Texting type="p2 grey18" style="line-height: 20px"
                      >Never for this campaign</Texting
                    >
                    <input
                      type="radio"
                      v-model="checked"
                      value="NONE"
                      name="radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="auto">
                  <Button
                    id="after-payment-later-button-850894f8"
                    type="cancel"
                    size="normal"
                    texting="p3bold grey2"
                    :handleClick="close"
                    >cancel</Button
                  >
                </v-col>
                <v-col cols="auto" style="padding-right: 0; padding-left: 3px">
                  <Button
                    id="after-payment-setup-button-8a34917a"
                    type="primary"
                    size="normal"
                    texting="p3bold mywhite"
                    :handleClick="sendTopUpNotificationType"
                    >save</Button
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import Button from "./Button";
import myServices from "../services/resource";
import Heading from "@/components/Heading";
import axios from "axios";

export default {
  components: {
    Heading,
    Button,
    Images,
    Texting,
  },
  props: {
    active: Boolean,
    project: Object,
  },
  data: () => ({
    dialog: false,
    checked: null,
  }),
  watch: {
    active: function (val) {
      if (val) {
        this.dialog = true;
        this.checked = this.project.topUpNotificationType;
      }
    },
    dialog: function (val) {
      if (!val) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    sendTopUpNotificationType() {
      axios
        .patch(`/api/notify/topUpNotificationType/${this.project.projectId}`, {
          topUpNotificationType: this.checked,
        })
        .then(() => {
          this.$store.dispatch("getProjects");
          this.$toasted
            .show("Changes have been saved successfully.", {
              type: "success",
              theme: "bubble",
            })
            .goAway(3000);
          this.close();
        })
        .catch((error) => {
          this.$v.$reset();
          myServices.showErrorMessage(this, error);
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #c7c4c4;
}
.radio-container input:checked ~ .checkmark {
  background-color: #6fcf97;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio-container input:checked ~ .checkmark:after {
  display: block;
}
input:checked ~ .checkmark {
  border: 2px solid #6fcf97;
}
.radio-container .checkmark:after {
  left: 2px;
  top: 3px;
  width: 12px;
  height: 6px;
  transform: rotate(-45deg);
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  background-color: #6fcf97;
}
</style>
