import { helpers } from "vuelidate/lib/validators";

export const kickOrIgg = (value) => {
  if (!helpers.req(value)) return true;

  const allowFormat1 = "kickstarter.com";
  const allowFormat2 = "indiegogo.com";

  return value.includes(allowFormat1) || value.includes(allowFormat2);
};
