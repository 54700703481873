<template>
  <table v-if="draftProjects.length > 0">
    <tr style="">
      <th width="4%" style="padding-left: 19px" />
      <th width="24%" style="padding-left: 10px" />
      <th width="20%" />
      <th width="20%">
        <div class="d-flex justify-center mb-3">
          <div
            v-if="!user.usedFreeTrial && notInArchive.length > 0"
            style="text-align: center"
          >
            <Texting type="p1black Fivethly">Get 3 Days for Free!</Texting>
            <Texting type="label1 Fivethly">on your first campaign</Texting>
          </div>
        </div>
      </th>
      <th width="33%" />
    </tr>
    <tr
      class="pr"
      v-for="(draftProject, i) in draftProjects"
      :key="i"
      @click="projectClicked(draftProject, $event)"
      :id="'draftProject-' + i"
    >
      <td>
        <a :href="draftProject.projectUrl">
          <Images
            :name="
              draftProject.projectUrl.includes('indiegogo')
                ? 'indiegogo.svg'
                : 'kickstarter.svg'
            "
            align="middle"
            class="pointer"
            style="display: block; margin: 0 auto"
          />
        </a>
      </td>
      <td
        style="padding-left: 10px; max-width: 500px; height: 100%"
        class="d-inline-flex align-center"
      >
        <div class="d-md-inline-flex d-sm-none d-none">
          <Texting type="p1 primColor">{{
            draftProject.projectTitle.length > 50
              ? draftProject.projectTitle.slice(0, 20) + "..."
              : draftProject.projectTitle
          }}</Texting>
        </div>
        <div class="d-md-none d-none d-sm-inline-flex">
          <Texting type="p1 primColor">
            {{ draftProject.projectTitle.slice(0, 20) + "..." }}
          </Texting>
        </div>
        <div class="d-md-none d-inline-flex d-sm-none">
          <Texting type="p1 primColor">
            {{ draftProject.projectTitle.slice(0, 6) + "..." }}
          </Texting>
        </div>
        <div
          style="
            padding: 2px 10px;
            border: 1px solid #a7a0b8;
            border-radius: 50px;
            display: inline-flex;
            margin-left: 15px;
          "
        >
          <Texting type="p1bold package-4-ind-color">{{
            draftProject.status === "CHECKING_LAUNCHING_SOON" ||
            draftProject.status === "LAUNCHING_SOON"
              ? "Launching Soon"
              : "Draft"
          }}</Texting>
        </div>
        <div
          v-if="
            i !== 0 &&
            draftProject.status !== 'CHECKING_LAUNCHING_SOON' &&
            draftProject.status !== 'LAUNCHING_SOON'
          "
          class="d-inline-flex ml-6"
        >
          <v-tooltip
            content-class="my-tooltip"
            color="rgba(38,39,40,0.9)"
            max-width="300px"
            top
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" class="d-flex align-center justify-center">
                <div class="contain-error">
                  <div class="d-flex error-outline">
                    <Images name="error_outline.svg" />
                  </div>
                </div>
              </div>
            </template>
            <Texting type="p1n mywhite" class="pt-2">{{
              getTooltipText(draftProject)
            }}</Texting>
            <div class="arrow-tooltip" style="left: 125px"></div>
          </v-tooltip>
        </div>
      </td>
      <!--            <td style="padding-left: 10px" class="d-md-none d-none d-sm-table-cell">
                <Texting type="p1 primColor" style="display: inline-block">{{
                    draftProject.projectTitle.slice(0, 20) + "..."
                }}</Texting>
            </td>
            <td style="padding-left: 10px" class="d-md-none d-sm-none d-table-cell">
                <Texting type="p1 primColor" style="display: inline-block">{{
                    draftProject.projectTitle.slice(0, 6) + "..."
                }}</Texting>
            </td>-->
      <td style="opacity: 0.99">
        <div
          class="d-flex pr-2"
          v-for="(currentDraftTimer, i) in draftTimer"
          :key="i"
        >
          <Heading
            v-if="showDraftMinutes(currentDraftTimer, draftProject)"
            type="h2-5 MainDark"
            >{{ draftMinutes(currentDraftTimer.countDown) }}</Heading
          >
          <Texting
            v-if="showDraftMinutes(currentDraftTimer, draftProject)"
            type="p4-1-3 MainDark"
            style="padding-top: 14px; padding-left: 4px; padding-right: 12px"
            >m</Texting
          >
          <Heading
            v-if="showDraftSeconds(currentDraftTimer, draftProject)"
            type="h2-5 MainDark"
            >{{ draftSeconds(currentDraftTimer.countDown) }}</Heading
          >
          <Texting
            v-if="showDraftSeconds(currentDraftTimer, draftProject)"
            type="p4-1-3 MainDark"
            style="padding-top: 14px; padding-left: 4px"
            >s</Texting
          >
        </div>
      </td>
      <td
        style="vertical-align: middle; display: flex"
        :style="{
          paddingTop:
            draftProject.status !== 'LAUNCHING_SOON' &&
            draftProject.status !== 'CHECKING_LAUNCHING_SOON'
              ? '24px'
              : '44px',
        }"
        class="justify-center"
      >
        <Button
          v-if="
            draftProject.status !== 'LAUNCHING_SOON' &&
            draftProject.status !== 'CHECKING_LAUNCHING_SOON'
          "
          @click.native="checkOrInvite(draftProject)"
          :handleClick="function () {}"
          type="secondary"
          size="small"
          texting="p1bold grey10"
          :disabled="
            checkingDrafts.includes(draftProject.draftProjectId) &&
            draftProject.status === 'FULL_EDITING_RIGHTS_NOT_GIVEN'
          "
          :style="{
            cursor:
              checkingDrafts.includes(draftProject.draftProjectId) &&
              draftProject.status === 'FULL_EDITING_RIGHTS_NOT_GIVEN'
                ? 'default'
                : 'pointer',
            backgroundColor:
              checkingDrafts.includes(draftProject.draftProjectId) &&
              draftProject.status === 'FULL_EDITING_RIGHTS_NOT_GIVEN'
                ? '#f4f4f4'
                : '',
          }"
          style="display: inline-flex"
          ><span style="text-transform: none; white-space: nowrap">{{
            draftProject.status === "FULL_EDITING_RIGHTS_NOT_GIVEN"
              ? "Check Now"
              : "Invite us as a Team Member"
          }}</span></Button
        >
        <v-progress-circular
          v-if="
            checkingDrafts.includes(draftProject.draftProjectId) &&
            draftProject.status === 'FULL_EDITING_RIGHTS_NOT_GIVEN'
          "
          :width="3"
          style="margin-bottom: 3px; margin-left: 20px; display: inline-flex"
          color="#9E33E0"
          size="30"
          indeterminate
        ></v-progress-circular>
      </td>
      <td style="padding-right: 20px !important">
        <Texting
          v-if="
            draftProject.status !== 'LAUNCHING_SOON' &&
            draftProject.status !== 'CHECKING_LAUNCHING_SOON'
          "
          type="p2 MainDark"
          @click.native="showInstructionsClick(draftProject)"
          class="show-instructions pointer float-end mr-10"
          style="opacity: 0.7; max-width: 177px"
          >Show instructions again</Texting
        >
        <div v-else class="d-inline-flex" style="padding-top: 6px">
          <Images name="error_outline.svg" />
          <Texting type="foralert MainDark" class="ml-2 mt-1"
            >Come back and activate after you launch your campaign on
            {{
              draftProject.projectUrl.includes("indiegogo")
                ? "Indiegogo"
                : "Kickstarter"
            }}.</Texting
          >
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import { mapState } from "vuex";
import myServices from "../services/resource";
import Texting from "./Texting";
import Images from "./MyImages";
import router from "../plugins/router";
import axios from "axios";
import Button from "@/components/Button";
import Heading from "@/components/Heading";

export default {
  components: {
    Heading,
    Button,
    Images,
    Texting,
  },
  props: {
    startTimer: Boolean,
    draftId: Array,
    notInArchive: Array,
    //removedraft : Array,
  },
  data: () => ({
    activateOpened: "",
    projectEnded: false,
    activate_clicked: false,
    my_project: null,
    checkingDrafts: [],
    draftTimer: [],
    stopTimer: false,
    checkDisabled: false,
    timerActive: false,
    /*testDrafts: [
        {
          "draftProjectId": "6edb02a7-a44f-4d88-82e4-fe168c569dd2",
          "userId": "82ba29e5-bc41-4cce-9e37-3cbda51e3a2e",
          "projectTitle": "tstand1",
          "projectUrl": "https://www.indiegogo.com/projects/tstand-2-holds-all-devices-anywhere-everywhere--4",
          "notify": true,
          "requestUrl": null,
          "hereLink": null,
          "status": "CHECKING",
          "Schedules": [
            {
              "scheduleDate": "2021-07-13T07:06:45.194Z",
              "scheduleType": "closePopup"
            },
            {
              "scheduleDate": "2021-07-22T17:19:45.194Z",
              "scheduleType": "deleteDraft"
            }
          ]
        },
        {
          "draftProjectId": "6edb02a7-a44f-4d88-82e4-fe171c569dd2",
          "userId": "82ba29e5-bc41-4cce-9e37-3cbda51e3a2e",
          "projectTitle": "tstand2",
          "projectUrl": "https://www.indiegogo.com/projects/tstand-2-holds-all-devices-anywhere-everywhere--4",
          "notify": true,
          "requestUrl": null,
          "hereLink": null,
          "status": "NO_INVITATION",
          "Schedules": [
            {
              "scheduleDate": "2021-07-13T07:06:45.194Z",
              "scheduleType": "closePopup"
            },
            {
              "scheduleDate": "2021-07-22T18:16:10.244Z",
              "scheduleType": "deleteDraft"
            }
          ]
        }
      ]*/
  }),
  mounted: function () {
    const myVue = this;
    window.addEventListener(
      "focus",
      function () {
        myVue.draftTimer = [];
        myVue.stopTimer = true;
        myVue.startDraftTimer();
      },
      false,
    );
    this.draftTimer = [];
    this.stopTimer = true;
    this.startDraftTimer();
  },
  watch: {
    startTimer: function (val) {
      if (val) {
        this.draftTimer = [];
        this.stopTimer = true;
        this.startDraftTimer();
      }
    },
    draftId(val) {
      if (val) {
        for (const el of this.checkingDrafts) {
          if (!val.includes(el)) {
            const index = this.checkingDrafts.indexOf(el);
            if (index > -1) {
              this.checkingDrafts.splice(index, 1);
            }
          }
        }
        //this.checkingDrafts = val;
        /*window.addEventListener("online", (event) => {
            console.log('length before',this.draftProjects.length);
            this.$store.dispatch("getDraftProjects").then(() => console.log('length after',this.draftProjects.length));
          });*/

        // if(this.checkingDrafts.includes(val)){
        //   const index = this.checkingDrafts.indexOf(val);
        //   if (index > -1) {
        //     this.checkingDrafts.splice(index, 1);
        //   }
        // }
      }
    },
  },

  computed: {
    ...mapState({
      draftProjects: "draftProjects",
      user: "user",
    }),
  },
  methods: {
    startDraftTimer() {
      this.$emit("timer-started");
      this.draftTimer = [];
      this.stopTimer = true;
      setTimeout(() => {
        this.draftTimer = [];
        this.stopTimer = true;
        if (this.draftProjects && this.draftProjects.length > 0) {
          for (const draft of this.draftProjects) {
            for (const Schedule of draft.Schedules) {
              if (
                Schedule.scheduleType === "DELETE_DRAFT" &&
                Schedule.scheduleDate &&
                (draft.status === "NO_INVITATION" ||
                  draft.status === "NO_ACCESS" ||
                  draft.status === "CHECKING" ||
                  draft.status === "CHECKING_LAUNCHING_SOON")
              ) {
                let t1 = new Date();
                let t2 = new Date(Schedule.scheduleDate);
                if (t2.getTime() > t1.getTime()) {
                  let dif = t2.getTime() - t1.getTime();
                  let Seconds_from_T1_to_T2 = dif / 1000;
                  let count = Math.floor(Seconds_from_T1_to_T2);
                  this.draftTimer.push({
                    id: draft.draftProjectId,
                    countDown: count,
                  });
                }
              }
            }
          }
          if (this.draftTimer.length > 0) {
            this.stopTimer = false;
            this.$nextTick(() => {
              if (!this.timerActive) {
                this.countDownTimer();
              }
            });
          }
        }
      }, 100);
    },
    showDraftMinutes(timer, draft) {
      if (
        draft.status === "NO_INVITATION" ||
        draft.status === "NO_ACCESS" ||
        draft.status === "CHECKING" ||
        draft.status === "CHECKING_LAUNCHING_SOON"
      ) {
        if (
          this.draftMinutes(timer.countDown) &&
          this.draftMinutes(timer.countDown) > 0 &&
          timer.id === draft.draftProjectId
        )
          return true;
        return false;
      }
    },
    showDraftSeconds(timer, draft) {
      if (
        draft.status === "NO_INVITATION" ||
        draft.status === "NO_ACCESS" ||
        draft.status === "CHECKING" ||
        draft.status === "CHECKING_LAUNCHING_SOON"
      ) {
        if (
          (this.draftSeconds(timer.countDown) > 0 ||
            this.draftMinutes(timer.countDown) > 0) &&
          timer.id === draft.draftProjectId
        )
          return true;
        return false;
      }
    },
    draftMinutes(countDown) {
      return Math.floor(countDown / 60);
    },
    draftSeconds(countDown) {
      return countDown % 60;
    },
    getTooltipText(draftProject) {
      if (draftProject.status === "FULL_EDITING_RIGHTS_NOT_GIVEN") {
        return (
          "We need more! Looks like you forgot to grant us the " +
          (draftProject.projectUrl.includes("kickstarter")
            ? "“Edit Project”"
            : "“Full Editing Rights”") +
          " privilege. Please check if you’ve done it correctly, and press the “Check Now” button below."
        );
      } else if (
        draftProject.status === "NO_INVITATION" ||
        draftProject.status === "NO_ACCESS" ||
        draftProject.status === "CHECKING"
      ) {
        return "We haven’t received the Collaboration Invitation! Make sure to send an invitation during next 10 minutes. If we don’t receive the Invite during that time the project will be removed from the Perkfection platform.";
      }
      // draftProject.status === "FULL_EDITING_RIGHTS_NOT_GIVEN"
      //   ? "We need more! Looks like you forgot to grant us the " +
      //     (draftProject.projectUrl.includes("kickstarter")
      //       ? "“Edit Project”"
      //       : "“Full Editing Rights”") +
      //     " privilege. Please check if you’ve done it correctly, and press the “Check Now” button below."
      //   : draftProject.status === "NO_INVITATION" ||
      //     draftProject.status === "NO_ACCESS" ||
      //     draftProject.status === "CHECKING"
      //   ? "We haven’t received the Collaboration Invitation! Make sure to send an invitation during next 10 minutes. If we don’t receive the Invite during that time the project will be removed from the Perkfection platform."
      //   : "";
    },
    showInstructionsClick(draft) {
      myServices.myAnalyticsEvents(
        this,
        "Draft",
        '"Show instructions" campaign line click',
      );
      this.$emit("open-invite", draft);
    },
    checkOrInvite(draft) {
      if (draft.status === "FULL_EDITING_RIGHTS_NOT_GIVEN") {
        myServices.myAnalyticsEvents(this, "Draft", '"Check Now" button click');
        this.$emit("start-connect", draft.draftProjectId);
        this.checkDisabled = true;
        //this.showLoading = draft.draftProjectId;
        this.checkingDrafts.push(draft.draftProjectId);
        /*const notifierCollab = new WebSocket(
                    `${process.env.VUE_APP_WS_URL}/?userId=${this.user.userId}&&status=collab`
                );

                notifierCollab.onmessage = (message) => {
                    const notification = JSON.parse(message.data);
                    console.log("notification.msg: checkOrInvite(drafts.vue)", notification.msg);

                    if (notification.msg === "INVITATION_ACCEPTED") {
                        this.$store.dispatch("getDraftProjects");
                        this.$store.dispatch("getProjects");
                        this.showLoading = null;
                    } else if (notification.msg === "CLOSE_POPUP") {
                        this.$store.dispatch("getDraftProjects");
                        this.$emit("start-timer");
                    } else if (notification.msg === "DELETE_DRAFT") {
                        this.$store.dispatch("getDraftProjects");
                    } else if (notification.msg === "FULL_EDITING_RIGHTS_NOT_GIVEN") {
                      this.$emit('open-error', draft);
                      this.showLoading = null;
                    }
                };*/

        axios
          .get(`/api/collaborator/checkAgain/${draft.draftProjectId}`)
          .then(() => {})
          .catch((error) => {
            myServices.showErrorMessage(this, error);
          });
      } else {
        myServices.myAnalyticsEvents(
          this,
          "Draft",
          '"Invite us as a team member" button click',
        );
        const projectUrlTeam = this.parseProjectUrl(draft.projectUrl);
        window.open(projectUrlTeam, "_blank");
      }
    },
    countDownTimer() {
      this.timerActive = true;
      setTimeout(() => {
        if (this.stopTimer) {
          this.timerActive = false;
          return;
        }
        if (!this.draftTimer.length || this.draftTimer.length === 0) {
          this.timerActive = false;
          return;
        }
        this.draftTimer.forEach(function (part, index) {
          let countDown = parseInt(this[index].countDown);
          if (countDown > 0) {
            this[index].countDown -= 1;
          }
        }, this.draftTimer);
        if (!this.stopTimer && this.draftTimer.length > 0)
          this.countDownTimer();
      }, 1000);
    },
    onClose: function () {
      this.activateOpened = "";
      this.activate_clicked = false;
    },
    parseProjectUrl(url) {
      let cut1 = url.split(".com/projects/")[1];

      if (url.split(".com/projects/")[0].includes("indiegogo")) {
        let cut2 = cut1.split("/")[0];

        if (cut2.includes("#")) cut2 = cut2.split("#")[0];
        if (cut2.includes("?")) cut2 = cut2.split("?")[0];

        const projectUrlTeam = `${
          url.split(".com/projects/")[0]
        }.com/campaigns/${cut2}/edit#/team`;

        return projectUrlTeam;
      } else if (url.split(".com/projects/")[0].includes("kickstarter")) {
        let cut2 = `${cut1.split("/")[0]}/${cut1.split("/")[1]}`;

        if (cut2.includes("#")) cut2 = cut2.split("#")[0];
        if (cut2.includes("?")) cut2 = cut2.split("?")[0];

        const projectUrlTeam = `${
          url.split(".com/projects/")[0]
        }.com/projects/${cut2}/collaborators`;

        return projectUrlTeam;
      }
    },
    projectClicked: function (project, event) {
      if (event.target.innerHTML !== "") {
        if (!project.isActiveProject && !project.hasActivated) {
          this.activateOpened = project.projectId;
        }
        if (
          (project.isActiveProject ||
            (!project.isActiveProject && project.hasActivated)) &&
          this.$route.path !== "/project/" + project.projectTitle
        ) {
          if (this.activate_clicked === false) {
            router.push({
              name: "MyRewards",
              params: {
                projectTitle: project.projectTitle,
              },
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.pr {
  border-bottom: 1px solid #f4f4f4;
  background-color: #ffffff;
  height: 80px;
}

.pr:hover {
  box-shadow: 0 1px 2px rgba(8, 35, 48, 0.24), 0 2px 6px rgba(8, 35, 48, 0.16);
  opacity: 0.95;
}

table {
  width: 100%;
  border-spacing: 0;
  min-width: 643px;
  box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
  border-collapse: collapse;
}

.pr:hover .mysettings {
  display: flex;
}
.show-instructions {
  border-bottom: 1px dashed #a7a0b8;
  border-top: none;
  border-left: none;
  border-right: none;
}
</style>
