var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('v-container',{staticStyle:{"background-color":"#f9f9f9","margin-bottom":"200px"},attrs:{"fluid":""}},[(
      (_vm.notInArchive && _vm.notInArchive.length > 0) ||
      (_vm.draftProjects && _vm.draftProjects.length > 0)
    )?_c('div',{staticStyle:{"overflow":"hidden"}},[_c('v-row',{staticStyle:{"border-bottom":"1px solid #f4f4f4","margin-bottom":"55px"}},[_c('v-col',{staticStyle:{"padding-top":"34px","padding-left":"30px","padding-right":"3px"},attrs:{"cols":"auto"}},[_c('Texting',{attrs:{"type":"p4 grey2"}},[_vm._v("My Campaigns")])],1),_c('v-col',{staticStyle:{"padding-top":"35px","margin-right":"20px","padding-bottom":"35px"},attrs:{"cols":"auto"}},[_c('div',{class:{ point: _vm.hover },staticStyle:{"height":"32px"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('Images',{staticClass:"pointer",staticStyle:{"position":"relative"},attrs:{"id":"myprojects-add-icon-4ae73e58","name":"add_projects_icon.svg"},nativeOn:{"click":function($event){return _vm.addClick.apply(null, arguments)}}})],1)]),(
          _vm.showDraftAlert() !== '' && _vm.draftProjects && _vm.draftProjects.length > 0
        )?_c('v-col',{staticClass:"draft-alert",attrs:{"cols":"10","md":"auto"}},[_c('Texting',{attrs:{"type":"p1alert grey17"},domProps:{"innerHTML":_vm._s(_vm.showDraftAlert())}})],1):_vm._e()],1),_c('div',[_c('v-row',{staticStyle:{"padding":"0 20px 10% 20px","overflow-x":"auto"}},[(
            _vm.draftProjects.length > 0 &&
            (!_vm.open_invite || (_vm.open_invite && _vm.invite_type === 'instruction'))
          )?_c('Drafts',{attrs:{"start-timer":_vm.startTimer,"draft-id":_vm.stopLoad(),"not-in-archive":_vm.notInArchive},on:{"timer-started":function($event){_vm.startTimer = false},"start-connect":function($event){return _vm.onStartConnect($event)},"open-invite":function($event){return _vm.showInstruction($event)}}}):_vm._e(),_c('Project',{attrs:{"ksdailywaiting":_vm.ksdailywaiting,"openactivate":_vm.openactivate},on:{"project-deleted":function($event){return _vm.$emit('project-deleted')}}})],1)],1)],1):_vm._e(),(_vm.notInArchive.length === 0 && _vm.draftProjects.length === 0)?_c('EmptyProject',{on:{"clicked":_vm.addClick}}):_vm._e(),_c('AddCampaign',{attrs:{"active":_vm.open()},on:{"closed":function($event){_vm.active = false},"next":_vm.handleNext}}),_c('Invite',{attrs:{"my_project":_vm.current_project,"start_connect":_vm.startConnect,"type":_vm.invite_type,"active":_vm.openInvite()},on:{"start-timer":function($event){_vm.startTimer = true},"closed":function($event){return _vm.onClose($event)},"message":function($event){return _vm.onMessage($event)}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }