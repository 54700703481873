<template>
  <div>
    <v-dialog
      id="invite-modal-bba193b2"
      overlay-opacity="0.99"
      :persistent="type !== 'instruction'"
      v-model="dialog"
      max-width="740px"
    >
      <v-card
        id="invite-card-c6d60de4"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          overflow: hidden;
        "
        v-if="my_project && getPlatform()"
      >
        <v-container class="px-0 overflow-hidden">
          <form>
            <v-row align="center" style="border-bottom: 1px solid #f4f4f4">
              <v-col
                cols="auto"
                style="padding-top: 0; padding-bottom: 6px; padding-left: 40px"
              >
                <Texting type="p2b MainDark" style="opacity: 0.8"
                  >Collaboration Invite</Texting
                >
              </v-col>
              <v-divider style="border: hidden" />
              <v-col cols="auto" style="padding-top: 8px; padding-right: 32px">
                <!--                            <ConfigQuit v-if="type !== 'instruction'" parent="invite" @discard="dialog = false" />-->
                <Images
                  id="invite-quit-icon-7faca292"
                  v-on:click.native="close"
                  class="pointer"
                  name="close.svg"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <v-row justify="center">
                  <div style="width: 100%"></div>
                  <v-col
                    v-if="!clicked || showInstuction"
                    cols="12"
                    class="justify-center d-flex"
                    style="padding-top: 35px; padding-bottom: 34px"
                    :order="showInstuction ? '4' : ''"
                  >
                    <Images
                      :name="
                        getPlatform() === 'indiegogo'
                          ? 'https://res.cloudinary.com/dyf7aejih/image/upload/v1625469090/images/indiegogo_invite_icon_appgxd.svg'
                          : 'https://res.cloudinary.com/dyf7aejih/image/upload/v1625468647/images/kickstarter_invite_icon_do0uqg.svg'
                      "
                    />
                  </v-col>
                  <div style="width: 100%"></div>
                  <v-col
                    v-if="clicked"
                    cols="auto"
                    style="padding-top: 25px; padding-bottom: 16px"
                    order="1"
                  >
                    <Images
                      name="https://res.cloudinary.com/dyf7aejih/image/upload/v1625475636/images/collab_partner_hoc46m.svg"
                    />
                  </v-col>
                  <div style="width: 100%"></div>
                  <v-col cols="12" v-if="clicked" order="2">
                    <Texting
                      type="p3bold MainDark"
                      style="opacity: 0.8; text-align: center"
                      >Don’t close this window.<br
                    /></Texting>

                    <div style="width: 100%"></div>
                    <Texting
                      type="p3 MainDark"
                      style="
                        opacity: 0.8;
                        text-align: center;
                        padding-top: 15px;
                        max-width: 430px;
                        margin: 0 auto !important;
                      "
                      >Make sure to send the invitation while it is open. The
                      verification process can take up to 3 min.</Texting
                    >
                  </v-col>
                  <div style="width: 100%"></div>
                  <v-col
                    v-if="!clicked || showInstuction"
                    cols="12"
                    class="pa-0"
                    order="5"
                  >
                    <video
                      width="100%"
                      title="instructions"
                      style="border: 1px solid #eadcec; border-radius: 8px"
                      autoplay
                      loop
                      muted
                      playsinline
                      preload
                    >
                      <source
                        :src="
                          getPlatform() === 'indiegogo'
                            ? 'https://res.cloudinary.com/dyf7aejih/video/upload/v1628077379/videos/Indiegogo_1_syxz6l.webm'
                            : 'https://res.cloudinary.com/dyf7aejih/video/upload/v1628077379/videos/Kickstarter_1_forgaq.webm'
                        "
                        type="video/webm"
                      />
                      <source
                        :src="
                          getPlatform() === 'indiegogo'
                            ? 'https://res.cloudinary.com/dyf7aejih/video/upload/v1628077261/videos/Indiegogo_1_py2tjg.mp4'
                            : 'https://res.cloudinary.com/dyf7aejih/video/upload/v1628077262/videos/Kickstarter_1_vzixaa.mp4'
                        "
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </v-col>
                  <div style="width: 100%"></div>
                  <v-col
                    v-if="!clicked || showInstuction"
                    cols="auto"
                    class="px-2"
                    style="padding-top: 36px"
                    order="6"
                  >
                    <Texting
                      type="p3 MainDark"
                      style="text-align: center; opacity: 0.8"
                    >
                      Invite
                      <input
                        style="position: absolute; left: -9999px"
                        type="text"
                        value="team@perkfection.com"
                        id="myInput"
                      />
                      <span style="color: #9e33e0; position: relative">
                        <div style="display: inline-block"></div>
                        <div class="copy-icon">
                          team@perkfection.com
                          <Images
                            id="invite-copy-icon-e9d8f6a8"
                            width="20"
                            height="20"
                            title="copy email"
                            :name="
                              copy ? 'copy_icon_after.png' : 'copy_icon.png'
                            "
                            @click.native="copyText"
                            class="pointer"
                            style="position: relative; top: 5px"
                          />
                        </div>
                      </span>
                      <span v-if="getPlatform() === 'indiegogo'"
                        >as a team member to your Indiegogo campaign. Don’t
                        forget to tick the box for
                        <b>"Grant full editing rights"</b>.</span
                      >
                      <span v-else
                        >as a team member to your Kickstarter campaign. Don’t
                        forget to tick all boxes in <b>"Edit Project"</b>.</span
                      >
                    </Texting>
                  </v-col>
                  <div style="width: 100%"></div>
                  <v-col
                    cols="auto"
                    style="padding-top: 20px"
                    :style="{ paddingBottom: showInstuction ? '0' : '30px' }"
                    :order="showInstuction ? '3' : '8'"
                  >
                    <Button
                      id="invite-us-button-02df2ef6"
                      v-if="!clicked"
                      b_type="submit"
                      size="normal"
                      :handleClick="inviteUs"
                      type="primary"
                    >
                      Invite
                      <span style="text-transform: lowercase">us as a</span>
                      team member
                    </Button>
                    <v-progress-circular
                      v-else
                      :width="4"
                      style="margin-bottom: 3px"
                      color="#9E33E0"
                      size="35"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                  <div style="width: 100%"></div>
                  <v-col
                    v-if="clicked"
                    cols="12"
                    class="justify-center d-flex"
                    :style="{ paddingBottom: showInstuction ? '40px' : '80px' }"
                    order="9"
                  >
                    <div class="d-flex pointer" @click="showInstuctionClick">
                      <Texting
                        type="p2 MainDark"
                        class="show-instructions"
                        style="opacity: 0.7"
                        >{{
                          showInstuction
                            ? "Hide instructions"
                            : "Show instructions again"
                        }}</Texting
                      >
                      <Images
                        style="margin-left: 8px; margin-top: 2px"
                        :name="
                          showInstuction
                            ? 'https://res.cloudinary.com/dyf7aejih/image/upload/v1617703049/images/up_vz6f2m.svg'
                            : 'https://res.cloudinary.com/dyf7aejih/image/upload/v1617703052/images/down_bhseaw.svg'
                        "
                      />
                    </div>
                  </v-col>
                  <div style="width: 100%"></div>
                  <v-col cols="auto" class="py-0" order="10">
                    <Texting
                      type="p1 MainDark"
                      style="opacity: 0.7; text-align: center; max-width: 530px"
                      >The information you provide is stored securely and is
                      only used by our system to monitor and update your stock
                      levels automatically.</Texting
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card>
    </v-dialog>
    <AddCampaignConfirmation
      ref="add_confirmation"
      @closed="project_title = ''"
      :project_title="project_title"
    />
    <CollabError
      :active="openErrorModal"
      @closed="
        openErrorModal = false;
        draft = {};
      "
      :project="draft"
    />
  </div>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import Button from "./Button";
import { mapState } from "vuex";

//import CollabError from "../CollabError/CollabError";
import AddCampaignConfirmation from "./AddCampaignConfirmation";
//import ConfigQuit from "@/components/ConfigQuit/ConfigQuit";
import myServices from "@/services/resource";
import axios from "axios";
import CollabError from "@/components/CollabError";

export default {
  components: {
    CollabError,
    AddCampaignConfirmation,
    Button,
    Images,
    Texting,
  },
  props: {
    active: {
      type: Boolean,
    },
    my_project: {
      type: Object,
    },
    type: String,
    start_connect: Boolean,
  },
  data: () => ({
    dialog: false,
    switch: false,
    quit: false,
    clicked: false,
    copy: false,
    openErrorModal: false,
    draft: {},
    project_title: "",
    anotherMessage: false,
    showInstuction: false,
    notifierCollab: null,
  }),
  computed: {
    ...mapState({
      user: "user",
      projects: "projects",
      draftProjects: "draftProjects",
    }),
  },
  watch: {
    start_connect(val) {
      if (val) {
        if (
          this.notifierCollab &&
          this.notifierCollab.readyState === this.notifierCollab.CLOSED
        ) {
          this.connect();
        }
      }
    },
    active: function (val) {
      if (val) {
        this.dialog = true;
      }
    },
    dialog: function (val) {
      if (!val) {
        //this.$emit("closed");
        //this.$emit('closed', 'startTimer');

        if (this.type !== "instruction") {
          this.$emit("closed", "startTimer");
        } else {
          this.$emit("closed");
        }
        this.clicked = false;
        this.showInstuction = false;
        this.copy = false;
      }
    },
  },
  mounted() {
    // window.addEventListener(
    //     "unload",
    //     (e) => {
    //         this.deleteDraft(this.my_project.projectTitle).then(() => {
    //             localStorage.setItem("draft", '')
    //         });
    //     }, {
    //         passive: true
    //     },
    //     true
    // );

    this.$store.dispatch("getDraftProjects").then((drafts) => {
      if (drafts.length > 0)
        if (
          (this.notifierCollab &&
            this.notifierCollab.readyState === this.notifierCollab.CLOSED) ||
          this.notifierCollab === null
        ) {
          this.connect();
        }
    });
  },
  methods: {
    showInstuctionClick() {
      this.showInstuction = !this.showInstuction;
      if (this.showInstuction) {
        myServices.myAnalyticsEvents(
          this,
          "Add campaign",
          'Click to "Show instructions again" pop-up',
        );
      }
    },
    getPlatform() {
      if (
        this.my_project.projectUrl &&
        this.my_project.projectUrl.includes("indiegogo")
      )
        return "indiegogo";
      if (
        this.my_project.projectUrl &&
        this.my_project.projectUrl.includes("kickstarter")
      )
        return "kickstarter";
      return false;
    },
    handleBeforeunload: function (e) {
      //e.preventDefault();
      e.returnValue = "close";
    },
    parseProjectUrl(url) {
      let cut1 = url.split(".com/projects/")[1];

      if (url.split(".com/projects/")[0].includes("indiegogo")) {
        let cut2 = cut1.split("/")[0];

        if (cut2.includes("#")) cut2 = cut2.split("#")[0];
        if (cut2.includes("?")) cut2 = cut2.split("?")[0];

        const projectUrlTeam = `${
          url.split(".com/projects/")[0]
        }.com/campaigns/${cut2}/edit#/team`;

        return projectUrlTeam;
      } else if (url.split(".com/projects/")[0].includes("kickstarter")) {
        let cut2 = `${cut1.split("/")[0]}/${cut1.split("/")[1]}`;

        if (cut2.includes("#")) cut2 = cut2.split("#")[0];
        if (cut2.includes("?")) cut2 = cut2.split("?")[0];

        const projectUrlTeam = `${
          url.split(".com/projects/")[0]
        }.com/projects/${cut2}/collaborators`;

        return projectUrlTeam;
      }
    },
    connect() {
      //console.log("enter CONNECT");
      this.notifierCollab = new WebSocket(
        `${process.env.VUE_APP_WS_URL}/?userId=${this.user.userId}&&status=collab`,
      );
      this.notifierCollab.onopen = function () {
        //console.log("WEBSOCKET_OPENED: client connected to server");
      };

      this.notifierCollab.onclose = (ev) => {
        //console.log("Socket is closed. Reconnect will be attempted in 1 second", ev.code);
        this.$store.dispatch("getDraftProjects").then((drafts) => {
          if (ev.code === 1006 && drafts.length > 0) {
            //console.log("Connect again");
            // setTimeout(() => this.connect(), 1000);
            this.connect();
          }
        });
      };

      this.notifierCollab.onerror = function (err) {
        // eslint-disable-next-line no-console
        console.error(
          "Socket encountered error: ",
          err.message,
          "Closing socket",
        );
        this.notifierCollab.close();
      };

      this.notifierCollab.onmessage = (message) => {
        const notification = JSON.parse(message.data);
        // eslint-disable-next-line no-console
        console.log(
          "notification.msg------",
          notification.msg,
          notification.id,
        );
        this.$emit("message", notification.id);
        // notification.msg === "FULL_EDITING_RIGHTS_NOT_GIVEN"
        //     ? this.$emit("message", notification.id)
        //     : this.$emit("message");
        //console.log("draftProjectId: ", this.draft.draftProjectId);
        //console.log("id: ", notification.id);
        // console.log("notification.msg: ", notification.msg);
        //console.log("true: ", this.draft.draftProjectId === notification.id);

        if (notification.msg === "INVITATION_ACCEPTED") {
          this.$store.dispatch("getDraftProjects").then(() => {
            this.project_title = notification.projectTitle;
            this.$refs.add_confirmation.open();
            this.$emit("start-timer");
            this.$store.dispatch("getProjects");
          });
        } else if (notification.msg === "CLOSE_POPUP") {
          //console.log("CLOSE_POPUP");
          /*console.log(
                        "readyState: ",
                        this.notifierCollab.readyState,
                        this.notifierCollab.CLOSED,
                        this.notifierCollab.CLOSING
                    );*/

          if (this.notifierCollab.readyState === this.notifierCollab.CLOSED) {
            this.connect();
          }
          this.$store.dispatch("getDraftProjects").then(() => {
            this.$emit("start-timer");
            this.dialog = false;
          });
        } else if (notification.msg === "DELETE_DRAFT") {
          this.$store.dispatch("getDraftProjects");
          //this.$emit('delete-draft', notification.id);
        } else if (notification.msg === "NO_ACCESS") {
          this.$store.dispatch("getDraftProjects").then(() => {
            this.$emit("start-timer");
          });
        } else {
          if (notification.msg === "FULL_EDITING_RIGHTS_NOT_GIVEN") {
            this.$store.dispatch("getDraftProjects").then((drafts) => {
              let draft = drafts.filter(
                (draft) => draft.draftProjectId === notification.id,
              )[0];
              this.draft = draft;
              //console.log(this.draft)
              this.openErrorModal = true;
              if (!this.dialog) {
                // this.$nextTick(()=> {
                //   console.log('open pop up---------', draft)
                //   this.$emit("open-error", draft);
                // })
                //   setTimeout(() => {
                //       console.log('open pop up, before emit---------', draft)
                //       this.$emit("open-error", draft);
                //   }, 300);
              }
              this.$emit("start-timer");
            });
          } else {
            this.$store.dispatch("getDraftProjects").then(() => {
              this.$emit("start-timer");
            });
          }
          if (this.notifierCollab.readyState === this.notifierCollab.CLOSED) {
            this.connect();
          }
        }
        if (
          this.draft.draftProjectId === notification.id &&
          notification.msg !== "DELETE_DRAFT" &&
          this.dialog
        ) {
          this.dialog = false;
        }
      };
    },
    inviteUs() {
      if (this.type === "instruction") {
        myServices.myAnalyticsEvents(
          this,
          "Draft",
          '"Invite us as a team member" campaign line pop-up click',
        );
        this.dialog = false;
        const projectUrlTeam = this.parseProjectUrl(this.my_project.projectUrl);
        window.open(projectUrlTeam, "_blank");
        return;
      }
      this.clicked = true;

      this.connect();

      myServices.myAnalyticsEvents(
        this,
        "Add campaign",
        'Click to "Invite us as a team member" button',
      );
      const projectUrlTeam = this.parseProjectUrl(this.my_project.projectUrl);

      window.open(projectUrlTeam, "_blank");
      axios
        .post("/api/draft/addDraftProject", this.my_project)
        .then((res) => {
          this.draft = res.data.data;
        })
        .catch((error) => {
          this.disabled = false;
          myServices.showErrorMessage(this, error);
        });
    },
    copyText() {
      let copyText = document.getElementById("myInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.copy = true;
    },
    onChange() {
      this.switch = !this.switch;
    },
    close() {
      this.dialog = false;
      //this.$emit("start-timer")
      if (this.type !== "instruction") {
        //this.$emit('closed', 'startTimer');
        //this.connect();
        this.$store.dispatch("getProjects");
        this.$store.dispatch("getDraftProjects");
      }
    },
  },
};
</script>

<style scoped>
.copy-icon {
  display: inline;
  position: relative;
  white-space: nowrap;
  margin-right: 5px;
  height: 30px;
  width: 30px;
}

.copy-icon:hover {
  background-color: #e8e8e8;
  border-radius: 50%;
}

.show-instructions {
  border-bottom: 1px dashed #a7a0b8;
  border-top: none;
  border-left: none;
  border-right: none;
}
</style>
