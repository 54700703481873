var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"id":"add-campaign-modal-92ee4760","overlay-opacity":"0.95","max-width":"657px","min-wi":""},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"background":"#ffffff","box-shadow":"0 18px 23px rgba(0, 0, 0, 0.05)","border-radius":"5px"},attrs:{"id":"add-campaign-card-a9ffbf56"}},[_c('v-container',[_c('form',{ref:"form",staticStyle:{"overflow":"hidden"},attrs:{"action":"#","id":"AddForm","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onProject.apply(null, arguments)}}},[_c('v-row',{staticStyle:{"border-bottom":"1px solid #f4f4f4"},attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticStyle:{"padding-top":"0","padding-bottom":"6px","padding-left":"0"},attrs:{"cols":"auto"}},[_c('Texting',{attrs:{"type":"p2bold grey2"}},[_vm._v("Add New Campaign")])],1),_c('v-divider',{staticStyle:{"border":"hidden"}}),_c('v-col',{staticStyle:{"padding-top":"8px","padding-right":"20px"},attrs:{"cols":"auto"}},[_c('Images',{staticClass:"pointer",attrs:{"name":"close.svg"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding-left":"0","padding-bottom":"0"},attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{class:{
                      'error--text v-input--has-state':
                        (_vm.$v.form.projectUrl.$dirty &&
                          !_vm.$v.form.projectUrl.required) ||
                        (_vm.$v.form.projectUrl.$dirty &&
                          !_vm.$v.form.projectUrl.url) ||
                        (_vm.$v.form.projectUrl.$error &&
                          !_vm.$v.form.projectUrl.kickOrIgg.valid),
                    },attrs:{"label":"Your Campaign Link*","name":"projectUrl","id":"projectUrl-input-d6f064e8","placeholder":"https://kickstarter.com/projects/123456/your-campaign-link","onfocus":"this.placeholder = ''","hide-details":"","outlined":""},model:{value:(_vm.form.projectUrl),callback:function ($$v) {_vm.$set(_vm.form, "projectUrl", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.projectUrl"}},[(
                        _vm.form.projectUrl.includes(
                          'https://www.kickstarter.com/'
                        )
                      )?_c('template',{slot:"append"},[_c('Images',{staticStyle:{"margin-top":"2px"},attrs:{"name":"kickstart.svg"}})],1):(
                        _vm.form.projectUrl.includes('https://www.indiegogo.com/')
                      )?_c('template',{slot:"append"},[_c('Images',{staticStyle:{"margin-top":"2px"},attrs:{"name":"go.svg"}})],1):(!_vm.$v.form.projectUrl.kickOrIgg)?_c('template',{slot:"append"},[_c('v-progress-circular',{staticStyle:{"margin-top":"3px"},attrs:{"size":20,"color":"#9E33E0","width":"2","indeterminate":""}})],1):_vm._e()],2),_c('div',{staticClass:"redColor",staticStyle:{"padding-top":"5px"}},[(
                        _vm.$v.form.projectUrl.$dirty &&
                        !_vm.$v.form.projectUrl.required
                      )?_c('Texting',{attrs:{"type":"p1"}},[_vm._v("Url is required")]):_vm._e(),(
                        _vm.$v.form.projectUrl.$dirty && !_vm.$v.form.projectUrl.url
                      )?_c('Texting',{attrs:{"type":"p1"}},[_vm._v("Url format is not valid.")]):_vm._e(),(
                        _vm.$v.form.projectUrl.$error &&
                        !_vm.$v.form.projectUrl.kickOrIgg.valid
                      )?_c('Texting',{attrs:{"type":"p1"}},[_vm._v("Must be 'Kickstarter' or 'Indiegogo'")]):_vm._e()],1)],1),_c('v-col',{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12"}},[_c('v-text-field',{class:{
                      'error--text v-input--has-state':
                        (_vm.$v.form.projectTitle.$dirty &&
                          !_vm.$v.form.projectTitle.required) ||
                        (_vm.$v.form.projectTitle.$error &&
                          !_vm.$v.form.projectTitle.projectTitleValid.valid &&
                          _vm.$v.form.projectTitle.required),
                    },attrs:{"label":"Campaign Title*","name":"projectTitle","id":"projectTitle-input-099897a8","placeholder":"Campaign Title","onfocus":"this.placeholder = ''","hide-details":"","outlined":""},model:{value:(_vm.form.projectTitle),callback:function ($$v) {_vm.$set(_vm.form, "projectTitle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.projectTitle"}}),_c('div',{staticClass:"redColor",staticStyle:{"padding-top":"5px"}},[(
                        _vm.$v.form.projectTitle.$dirty &&
                        !_vm.$v.form.projectTitle.required
                      )?_c('Texting',{attrs:{"type":"p1"}},[_vm._v("Title is required")]):(
                        _vm.$v.form.projectTitle.$error &&
                        !_vm.$v.form.projectTitle.projectTitleValid.valid &&
                        _vm.$v.form.projectTitle.required &&
                        this.form.projectTitle.length < 60
                      )?_c('Texting',{attrs:{"type":"p1"}},[_vm._v("Title should contain only letters and numbers (a-z/A-Z/0-9)")]):(this.form.projectTitle.length >= 60)?_c('Texting',{attrs:{"type":"p1"}},[_vm._v("Title should be less than 60 characters")]):_vm._e()],1)],1)],1)],1)],1),_c('v-row',{staticStyle:{"border-bottom":"1px solid #f4f4f4"},attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding-left":"0","padding-top":"0","padding-bottom":"26px"},attrs:{"cols":"10"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticStyle:{"max-width":"57px"},attrs:{"cols":"2"}},[_c('MySwitch',{attrs:{"id":"add-campaign-email-switch-2cd988e4","outline":true,"checked":this.switch},on:{"change":_vm.onChange}})],1),_c('v-col',{staticStyle:{"padding-left":"28px"},attrs:{"cols":"8"}},[_c('Texting',{attrs:{"type":"p1 grey2"}},[_vm._v("Email me when my perks are topped up")])],1),(_vm.$v.form.topUpNotificationType.$error)?_c('div',{staticClass:"redColor"},[_c('Texting',[_vm._v("Please choose one")])],1):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding-top":"18px","padding-bottom":"4px"},attrs:{"cols":"10"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticStyle:{"padding-right":"4px"},attrs:{"cols":"auto"}},[_c('Button',{attrs:{"id":"add-campaign-cancel-button-63d5e22a","texting":"p2bold grey2","size":"normal","handleClick":_vm.close,"type":"cancel"}},[_vm._v("cancel")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('Button',{attrs:{"id":"add-campaign-next-button-7691071e","b_type":"submit","size":"normal","handleClick":_vm.nextAction,"type":"primary"}},[_vm._v("next")])],1)],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }