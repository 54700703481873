<template>
  <div>
    <v-dialog
      id="collab-error-modal-d153ff72"
      overlay-opacity="0.95"
      v-model="dialog"
      max-width="657px"
      min-wi
    >
      <v-card
        id="collab-error-card-0ba560f8"
        v-if="project.projectUrl"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
        "
      >
        <v-container>
          <v-row align="center" justify="end">
            <v-col cols="auto" style="padding-top: 8px; padding-right: 20px">
              <Images
                id="collab-error-close-icon-1fcec592"
                name="close.svg"
                class="pointer"
                v-on:click.native="close"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="11">
              <v-row justify="center">
                <v-col
                  cols="auto"
                  style="padding-top: 15px; padding-bottom: 20px"
                >
                  <Images
                    id="collab-error-copy-icon-36a81d40"
                    name="attention_red.png"
                  />
                </v-col>
                <div style="width: 100%"></div>
                <v-col cols="auto" style="padding-bottom: 14px">
                  <Heading type="h3 MainDark" style="text-align: center"
                    >We need more</Heading
                  >
                </v-col>
                <div style="width: 100%"></div>
                <v-col cols="auto" style="padding-top: 0; max-width: 544px">
                  <Texting
                    type="p3 MainDark"
                    style="text-align: center; opacity: 0.8"
                    v-html="
                      'Looks like you forgot to grant us the <b>' +
                      (project.projectUrl.includes('kickstarter')
                        ? '“Full Editing Rights”'
                        : '“Full Editing Rights”') +
                      '</b> privilege. Please check if you’ve done it correctly, and press the “Check Now” button after that.'
                    "
                  ></Texting>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="auto"
                  style="padding-top: 28px; padding-bottom: 24px"
                >
                  <Button
                    id="collab-error-check-button-5032e1b4"
                    b_type="button"
                    size="normal"
                    :handleClick="closeModal"
                    type="primary"
                  >
                    OK
                  </Button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import myServices from "../services/resource";
import Texting from "./Texting";
import Images from "./MyImages";
import Button from "./Button";
import Heading from "@/components/Heading";

export default {
  components: { Heading, Button, Images, Texting },
  props: {
    active: Boolean,
    project: Object,
  },
  data: () => ({
    dialog: false,
    checkVal: false,
  }),
  watch: {
    active: function (val) {
      if (val) {
        this.dialog = val;
      }
    },
    dialog: function (val) {
      if (!val) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    // check() {
    // 	this.checkVal = true;
    // 	this.$emit("start_loading");
    // 	console.log(this.$props.draftProjectId);
    //
    // 	axios
    // 		.get(`/api/collaborator/checkAgain/${this.$props.project.projectTitle}`)
    // 		.then(() => {})
    // 		.catch((error) => {
    //       this.open();
    //       this.disabled = false;
    //       myServices.showErrorMessage(this, error);
    // 		});
    // },
    close() {
      this.dialog = false;
    },
    closeModal() {
      this.dialog = false;
      myServices.myAnalyticsEvents(
        this,
        "Draft",
        '"We need more" pop-up "OK" button click',
      );
    },
  },
};
</script>
