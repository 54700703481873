<template>
  <div
    id="home_user_container"
    @click="clicked($event)"
    style="height: 100%; background-color: #f9f9f9; overflow: hidden"
    class="pa-0"
    v-if="isProjectsResolved && isAuthResolved"
  >
    <AfterPaymentConfirmation
      @waiting-for-ksdaily="ksdailywaiting = true"
      v-if="activeConfirmation()"
      :project_activated="project_activated"
    />
    <Confirmation
      v-if="$route.query.ConfirmationModal"
      :active="$route.query.ConfirmationModal"
      text1="Password has been changed successfully!"
    />
    <AppHeaderUser
      :project-deleted="projectDeleted"
      :menu="close_menu"
      @opened="menu = true"
      @closed="onClosed"
      v-if="user"
    />
    <MyProjects
      @project-deleted="projectDeleted = true"
      :ksdailywaiting="ksdailywaiting"
      :openactivate="openactivate"
    />
    <top-up-summary-modal
      :active="openTopUpSummary"
      :project="my_project"
      @closed="onTopUpSummaryClose"
    />
    <div style="height: 100px">
      <AppFooter />
    </div>
  </div>
</template>

<script>
import MyProjects from "../components/MyProjects";
import { mapState } from "vuex";
import AppHeaderUser from "../components/AppHeaderUser";
import AppFooter from "../components/AppFooter";
import AfterPaymentConfirmation from "../components/AfterPaymentConfirmation";
import Confirmation from "../components/Confirmation";
import router from "@/plugins/router";
import TopUpSummaryModal from "@/components/TopUpSummaryModal";
//import router from "../../router";
export default {
  components: {
    TopUpSummaryModal,
    Confirmation,
    AfterPaymentConfirmation,
    MyProjects,
    AppHeaderUser,
    AppFooter,
  },
  props: {
    ksdailywaiting: Boolean,
  },
  data: () => ({
    menu: false,
    close_menu: false,
    project_activated: false,
    openactivate: false,
    openTopUpSummary: false,
    my_project: null,
    projectDeleted: false,
  }),
  computed: {
    ...mapState({
      user: "user",
      projects: "projects",
      isAuthResolved: "isAuthResolved",
      isProjectsResolved: "isProjectsResolved",
    }),
  },
  watch: {
    $route(to) {
      if (to.query.activated === "true") {
        this.project_activated = true;
      }
    },
  },
  created() {
    if (this.$route.query.topupsummary === "true") {
      setTimeout(() => {
        let my_project = this.projects.filter(
          (pr) =>
            pr.projectId === this.$route.query.projectid &&
            pr.inArchive === false,
        )[0];
        if (my_project) {
          this.my_project = my_project;
          this.openTopUpSummary = true;
          // this.$router.replace({
          //   query: null,
          // });
        }
      }, 1000);
    }
    if (this.$route.query.userId) {
      if (this.user && this.user.userId === this.$route.query.userId) {
        setTimeout(() => {
          this.$router.replace({
            query: null,
          });
        }, 1500);
      } else {
        this.$store.dispatch("logout").then(() => {
          let newQuery = this.$route.query;
          //newQuery.login = 'true';
          router.push({ path: "/", query: newQuery });
          localStorage.setItem("logout-event", "logout" + Math.random());
        });
      }
    }
    if (this.$route.query.activate) {
      this.openactivate = true;
    }
    this.$store.dispatch("getProjects");
    this.$store.dispatch("getDraftProjects");
    setTimeout(() => {
      if (this.$route.query.activated === "true") {
        let my_project = this.projects.filter(
          (pr) =>
            pr.projectId === this.$route.query.projectid &&
            pr.inArchive === false,
        )[0];
        if (
          my_project.projectState !== "" &&
          my_project.projectState !== null
        ) {
          this.$router.replace({
            query: null,
          });
          localStorage.setItem("after_payment_confirmation_canceled", "");
        } else {
          this.project_activated = true;
        }
      } else {
        if (
          localStorage.getItem("after_payment_confirmation_canceled") !== "" &&
          localStorage.getItem("after_payment_confirmation_canceled")
        ) {
          window.location.href = localStorage.getItem(
            "after_payment_confirmation_canceled",
          );
        }
      }
    }, 1000);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
    this.$mixpanel.identify(this.user.userId);
    this.$mixpanel.people.set({
      $name: this.user.displayName,
      $email: this.user.email,
      "Sign up date": this.user.createdAt,
    });
  },
  methods: {
    onTopUpSummaryClose() {
      if (this.$route.query.topupsummary) {
        this.$router.replace({
          query: null,
        });
      }
      this.openTopUpSummary = false;
      //this.$store.dispatch("getProjects");
    },
    activeConfirmation: function () {
      if (this.project_activated) {
        let my_project = this.projects.filter(
          (pr) =>
            pr.projectId === this.$route.query.projectid &&
            pr.inArchive === false,
        )[0];
        return !(
          my_project.projectState !== "" &&
          my_project.projectState !== null &&
          localStorage.getItem("after_payment_confirmation") === "false"
        );
      }
    },
    onClosed() {
      this.menu = false;
      this.close_menu = false;
    },
    clicked(event) {
      if (this.menu) {
        const myMenu = document.getElementById("my-account-credentials");
        let targetElement = event.target;
        do {
          if (
            targetElement == myMenu ||
            (targetElement !== null &&
              targetElement.className == "vti__dropdown-list below")
          ) {
            return;
          }
          targetElement = targetElement.parentNode;
        } while (targetElement);

        this.close_menu = true;
      }
    },
  },
  metaInfo: {
    title: "Perkfection: Automate Your Kickstarter & Indiegogo Rewards",
    meta: [
      {
        name: "description",
        content:
          "Increase your Kickstarter & Indiegogo conversions by 23%. Perkfection automates your rewards with 30-second top-ups, so there’s always only a few left!",
      },
      {
        property: "og:description",
        content:
          "Increase your Kickstarter & Indiegogo conversions by 23%. Perkfection automates your rewards with 30-second top-ups, so there’s always only a few left!",
      },
      {
        property: "og:title",
        content: "Perkfection: Automate Your Kickstarter & Indiegogo Rewards",
      },
      {
        property: "og:site_name",
        content: "Perkfection",
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: "https://perkfection.com",
      },
      {
        property: "og:image",
        content: "https://storage.googleapis.com/btest6.ao.am/facebook.png",
      },
      {
        name: "keywords",
        content:
          "Kickstarter rewards, Indiegogo perks, Kickstarter promotion, how to win at Kickstarter, kickstarter marketing, best way to promote your kickstarter project, how to promote kickstarter, how to promote your kickstarter, promote kickstarter, promote kickstarter campaign, kickstarter promotion services",
      },
    ],
  },
};
</script>
