<template>
  <table v-if="notInArchive.length > 0">
    <tr
      :style="{
        visibility: draftProjects.length > 0 ? 'collapse !important' : '',
      }"
    >
      <th width="4%" style="padding-left: 19px" />
      <th width="25%" style="padding-left: 10px" />
      <th width="20%" />
      <th width="20%">
        <div class="d-flex justify-center mb-3">
          <div v-if="!user.usedFreeTrial" style="text-align: center">
            <Texting type="p1black Fivethly">Get 3 Days for Free!</Texting>
            <Texting type="label1 Fivethly">on your first campaign</Texting>
          </div>
        </div>
      </th>
      <th width="5%"></th>
      <th width="15%" />
      <th width="11%" style="padding-right: 18px; min-width: 60px" />
    </tr>
    <tr
      class="pr"
      v-for="(project, i) in notInArchive"
      :key="project.projectId"
      @click="projectClicked(project, $event)"
      :id="'project-' + i"
    >
      <td>
        <a :href="project.projectUrl">
          <Images
            :name="project.platform + '.svg'"
            align="middle"
            class="pointer"
            style="display: block; margin: 0 auto"
            :style="{ opacity: checkEndDate(project) ? '' : '.4' }"
          />
        </a>
      </td>
      <td
        style="padding-left: 10px; max-width: 500px"
        class="d-md-table-cell d-sm-none d-none"
      >
        <Texting
          :style="{ opacity: checkEndDate(project) ? '' : '.4' }"
          type="p1 primColor"
          >{{ project.projectTitle }}</Texting
        >
      </td>
      <td style="padding-left: 10px" class="d-md-none d-none d-sm-table-cell">
        <Texting
          :style="{ opacity: checkEndDate(project) ? '' : '.4' }"
          type="p1 primColor"
          >{{ project.projectTitle.slice(0, 20) + "..." }}</Texting
        >
      </td>
      <td style="padding-left: 10px" class="d-md-none d-sm-none d-table-cell">
        <Texting
          :style="{ opacity: checkEndDate(project) ? '' : '.4' }"
          type="p1 primColor"
          >{{ project.projectTitle.slice(0, 6) + "..." }}</Texting
        >
      </td>
      <td style="opacity: 0.99">
        <div v-if="!checkEndDate(project)" class="text-center">
          <Texting type="p1 primColor">~ Ended Campaign ~</Texting>
        </div>
        <div v-if="showActionRequired(project) && checkEndDate(project)">
          <v-tooltip
            content-class="my-tooltip"
            color="rgba(38,39,40,0.9)"
            max-width="180px"
            top
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" class="d-flex align-center justify-center">
                <div class="contain-error">
                  <div
                    class="d-flex"
                    :class="{
                      'error-outline':
                        project.projectState === 'hasPerks' ||
                        project.projectState === 'hasPerksAndRefData',
                    }"
                  >
                    <Images name="error_outline.svg" />
                  </div>
                </div>
                <div class="d-flex pt-1 ml-2">
                  <Texting type="p1bold Fivethly">Action Required</Texting>
                </div>
              </div>
            </template>
            <Texting type="p1n mywhite">{{
              project.projectState === "hasPerks" ||
              project.projectState === "hasPerksAndRefData"
                ? "Monitoring has been started. You need to setup your Perks"
                : "You need to activate Perkfection for your campaign in order to proceed"
            }}</Texting>
            <div class="arrow-tooltip" style="left: 62px"></div>
          </v-tooltip>
        </div>
      </td>
      <td style="vertical-align: center">
        <div class="d-flex align-center justify-center">
          <div
            v-if="!project.isActiveProject && checkEndDate(project)"
            class="d-flex"
          >
            <ActivateProject
              @click.native="activateClick(project)"
              :active="activateOpened === project.projectId"
              style="margin: 0"
              :project="project"
              @close="onClose"
            />
          </div>
          <div
            v-else
            id="setup-perks-button-ee8e1240"
            @click="clickSetupPerks"
            class="pointer d-flex setup-perks"
            style="white-space: nowrap"
          >
            <router-link
              :to="{
                name: 'MyRewards',
                params: { projectTitle: project.projectTitle },
              }"
              type="p1bold grey10"
              style="text-align: right; text-decoration: none"
            >
              <div v-if="checkEndDate(project)" class="d-flex align-center">
                <div class="d-flex mr-2">
                  <Images name="edit.svg" />
                </div>
                <div class="d-flex pt-1">
                  <Texting type="p1bold grey10">Setup Perks</Texting>
                </div>
              </div>
              <div v-else class="d-flex align-center">
                <Texting class="pointer" type="p1bold grey10"
                  >View Perk's History</Texting
                >
              </div>
            </router-link>
          </div>
        </div>
      </td>
      <td>
        <div
          id="ks-daily-button-06ebe97a"
          v-if="
            project.platform === 'kickstarter' &&
            (project.projectState === 'hasPerksAndRefData' ||
              project.projectState === 'setupPerkAndRefData' ||
              project.hasActivated)
          "
          @click.stop="onClickKsDaily(project)"
          class="d-flex align-center setup-perks pointer"
        >
          <Images name="ks_daily_icon.svg" />
          <Texting
            type="p1bold grey10"
            style="white-space: nowrap; padding-top: 3px; padding-left: 6px"
            >KS Daily</Texting
          >
        </div>
        <div v-else style="width: 90px"></div>
      </td>
      <td style="vertical-align: center">
        <Texting
          type="p1 primColor"
          class="pl-3 text-center"
          :style="{
            color:
              project.isActiveProject &&
              Math.round(
                Math.abs(new Date(project.packageEndDate) - new Date()) /
                  (60 * 60 * 24 * 1000),
              ) < 1
                ? '#FA5C7C'
                : '',
          }"
          >{{
            project.hasActivated &&
            !project.isActiveProject &&
            checkEndDate(project)
              ? "Monitoring Ended"
              : campaignEnded(project)
          }}</Texting
        >
      </td>
      <td style="vertical-align: center; min-width: 96px">
        <CampaignConfiguration
          @project-deleted="$emit('project-deleted')"
          class="mysettings"
          :project="project"
        />
        <div
          v-if="!checkEndDate(project)"
          class="d-inline-flex mr-4 archive-icon float-right"
        >
          <v-tooltip
            content-class="my-tooltip"
            color="rgba(38,39,40,0.9)"
            max-width="200px"
            top
          >
            <template v-slot:activator="{ on }">
              <span style="height: 33px" v-on="on">
                <div
                  style="height: 33px; width: 33px"
                  class="justify-center d-flex align-center"
                >
                  <Images
                    id="project-archive-icon-4257e040"
                    v-on:click.native="sendToArchive(project.projectId)"
                    class="pointer"
                    name="archive__icon.svg"
                    style="position: relative; bottom: 1px"
                  />
                </div>
              </span>
            </template>
            <Texting type="p1n mywhite">Send to Archive</Texting>
            <div class="arrow-tooltip" style="left: 39px"></div>
          </v-tooltip>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import { mapState } from "vuex";
import myServices from "../services/resource";
import Texting from "./Texting";
import Images from "./MyImages";
import ActivateProject from "./ActivateProject";
import router from "../plugins/router";
import CampaignConfiguration from "@/components/CampaignConfiguration";
import axios from "axios";

export default {
  components: {
    CampaignConfiguration,
    ActivateProject,
    Images,
    Texting,
  },
  props: {
    openactivate: Boolean,
    ksdailywaiting: Boolean,
  },
  data: () => ({
    activateOpened: "",
    active_perks: {},
    projectEnded: false,
    activate_clicked: false,
    my_project: null,
  }),
  watch: {
    ksdailywaiting: function (value) {
      if (value) {
        const notifier = new WebSocket(
          `${process.env.VUE_APP_WS_URL}/?userId=${this.user.userId}&&status=ksDaily`,
        );
        notifier.onmessage = () => {
          this.$store.dispatch("getProjects");
        };
      }
    },
  },
  // sockets: {
  //     connect() {
  //         console.log("connected");
  //     },
  //     disconnect() {
  //         console.log("disconnected");
  //     },
  //     ksDaily(data) {
  //         console.log(data);
  //         this.$store.dispatch("getProjects");
  //         this.$socket.client.disconnect();
  //     },
  // },
  // created() {
  //     console.log("VUE_APP_BASE_URL: ", process.env.VUE_APP_BASE_URL);
  //     this.socket = io(process.env.VUE_APP_BASE_URL);
  //     console.log("email: ", this.user.email);
  //     this.socket.emit("join", { email: this.user.email });
  // },
  // mounted() {
  //     const notifier = new WebSocket(`${process.env.VUE_APP_WS_URL}/?email=${this.user.email}`)
  //     notifier.onmessage = (message) => {
  //         const notification = JSON.parse(message.data)
  //         this.$store.dispatch("getProjects");
  //     }
  // },
  computed: {
    ...mapState({
      projects: "projects",
      draftProjects: "draftProjects",
      perks: "perks",
      user: "user",
    }),
    notInArchive: function () {
      return this.projects.filter((project) => project.inArchive === false);
    },
  },
  mounted() {
    if (this.$route.query.activate && this.$route.query.activate !== "true") {
      let my_project = this.projects.filter(
        (pr) =>
          pr.projectId === this.$route.query.activate && pr.inArchive === false,
      )[0];
      if (my_project) {
        //console.log('uraaaaaaaaaaaa')
        this.activateOpened = this.$route.query.activate;
      }
      if (!my_project) {
        this.$store
          .dispatch("logout")
          .then(() => {
            //this.$router.push("/");
            router.push({ path: "/", query: { login: "true" } });
            localStorage.setItem("logout-event", "logout" + Math.random());
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.log(err));
      }
    }
  },
  methods: {
    onClickKsDaily(pr) {
      myServices.myAnalyticsEvents(this, "KS Daily", "KS Daily button click");
      router.push({
        path: `/ksdaily/${pr.projectTitle}`,
      });
    },
    clickSetupPerks() {
      myServices.myAnalyticsEvents(
        this,
        "Perk setup",
        '"setup-perks" button click',
      );
    },
    activateClick(project) {
      this.activate_clicked = true;
      if (project.hasActivated && !project.isActiveProject) {
        myServices.myAnalyticsEvents(this, "Reactivate", "Reactivate");
      } else {
        myServices.myAnalyticsEvents(
          this,
          "Activate campaign",
          "Activate button click",
        );
      }
    },
    sendToArchive(id) {
      axios
        .put(`/api/project/inArchive/${id}`)
        .then(() => {
          this.$store.dispatch("getProjects");
        })
        .catch((error) => {
          myServices.showErrorMessage(this, error);
        });
    },
    checkEndDate(project) {
      if (project.campaignStatus === "INDEMAND") return true;
      if (project.campaignEndDate) {
        return (
          new Date(project.campaignEndDate.toString()).getTime() >
          new Date().getTime()
        );
      } else {
        return true;
      }
    },
    onClose: function () {
      this.activateOpened = "";
      this.my_project = null;
      this.activate_clicked = false;
    },
    showActionRequired: function (project) {
      if (
        (!project.isActiveProject && !project.hasActivated) ||
        project.projectState === "hasPerks" ||
        project.projectState === "hasPerksAndRefData"
      ) {
        return true;
      }
    },
    projectClicked: function (project, event) {
      if (event.target.innerHTML !== "") {
        if (!project.isActiveProject && !project.hasActivated) {
          this.activateOpened = project.projectId;
        }
        if (
          (project.isActiveProject ||
            (!project.isActiveProject && project.hasActivated)) &&
          this.$route.path !== "/project/" + project.projectTitle
        ) {
          if (this.activate_clicked === false) {
            router.push({
              name: "MyRewards",
              params: {
                projectTitle: project.projectTitle,
              },
            });
          }
        }
      }
    },
    campaignEnded: function (x) {
      if (!this.checkEndDate(x)) {
        return "";
      }
      if (x.isActiveProject) {
        let end_date = x.packageEndDate;
        if (new Date(end_date).getTime() < new Date().getTime()) {
          return "Monitoring Ended";
        }
        if (
          Math.round(
            Math.abs(new Date(end_date) - new Date()) / (60 * 60 * 24 * 1000),
          ) < 1
        ) {
          let seconds = Math.floor(
            ((new Date(end_date) - new Date()) / 1000) % 60,
          );
          let minutes = Math.floor(
            ((new Date(end_date) - new Date()) / (1000 * 60)) % 60,
          );
          let hours = Math.floor(
            ((new Date(end_date) - new Date()) / (1000 * 60 * 60)) % 24,
          );

          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
          return `${hours}:${minutes}:${seconds} Left`;
        } else {
          if (x.packageType !== "ONCE") {
            let datetime = new Date(end_date);
            datetime.setHours(datetime.getHours() - 2);
            if (
              Math.ceil(
                Math.abs(new Date(datetime) - new Date()) /
                  (60 * 60 * 24 * 1000),
              ) === 1
            ) {
              return `${Math.ceil(
                Math.abs(new Date(datetime) - new Date()) /
                  (60 * 60 * 24 * 1000),
              )} Day Left`;
            } else {
              return `${Math.ceil(
                Math.abs(new Date(datetime) - new Date()) /
                  (60 * 60 * 24 * 1000),
              )} Days Left`;
            }
          } else {
            if (
              Math.ceil(
                Math.abs(new Date(end_date) - new Date()) /
                  (60 * 60 * 24 * 1000),
              ) === 1
            ) {
              return `${Math.ceil(
                Math.abs(new Date(end_date) - new Date()) /
                  (60 * 60 * 24 * 1000),
              )} Day Left`;
            } else {
              return `${Math.ceil(
                Math.abs(new Date(end_date) - new Date()) /
                  (60 * 60 * 24 * 1000),
              )} Days Left`;
            }
          }
        }
      } else if (!x.hasActivated) {
        return "- - -";
      }
    },
  },
};
</script>

<style scoped>
.pr {
  border-bottom: 1px solid #f4f4f4;
  background-color: #ffffff;
  height: 80px;
}

.pr:hover {
  box-shadow: 0 1px 2px rgba(8, 35, 48, 0.24), 0 2px 6px rgba(8, 35, 48, 0.16);
  opacity: 0.95;
}

table {
  width: 100%;
  border-spacing: 0;
  min-width: 643px;
  box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
  border-collapse: collapse;
}

.pr:hover .mysettings {
  display: flex;
}
</style>
