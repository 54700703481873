<template>
  <v-container
    v-if="user"
    style="background-color: #f9f9f9; margin-bottom: 200px"
    fluid
  >
    <div
      v-if="
        (notInArchive && notInArchive.length > 0) ||
        (draftProjects && draftProjects.length > 0)
      "
      style="overflow: hidden"
    >
      <v-row style="border-bottom: 1px solid #f4f4f4; margin-bottom: 55px">
        <v-col
          cols="auto"
          style="padding-top: 34px; padding-left: 30px; padding-right: 3px"
        >
          <Texting type="p4 grey2">My Campaigns</Texting>
        </v-col>
        <v-col
          cols="auto"
          style="padding-top: 35px; margin-right: 20px; padding-bottom: 35px"
        >
          <div
            style="height: 32px"
            :class="{ point: hover }"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            <Images
              id="myprojects-add-icon-4ae73e58"
              name="add_projects_icon.svg"
              style="position: relative"
              class="pointer"
              @click.native="addClick"
            />
          </div>
        </v-col>
        <v-col
          cols="10"
          md="auto"
          v-if="
            showDraftAlert() !== '' && draftProjects && draftProjects.length > 0
          "
          class="draft-alert"
        >
          <Texting type="p1alert grey17" v-html="showDraftAlert()"></Texting>
        </v-col>
      </v-row>
      <div>
        <v-row style="padding: 0 20px 10% 20px; overflow-x: auto">
          <Drafts
            v-if="
              draftProjects.length > 0 &&
              (!open_invite || (open_invite && invite_type === 'instruction'))
            "
            :start-timer="startTimer"
            @timer-started="startTimer = false"
            :draft-id="stopLoad()"
            @start-connect="onStartConnect($event)"
            @open-invite="showInstruction($event)"
            :not-in-archive="notInArchive"
          />
          <Project
            @project-deleted="$emit('project-deleted')"
            :ksdailywaiting="ksdailywaiting"
            :openactivate="openactivate"
          />
        </v-row>
      </div>
    </div>

    <EmptyProject
      @clicked="addClick"
      v-if="notInArchive.length === 0 && draftProjects.length === 0"
    />
    <AddCampaign @closed="active = false" @next="handleNext" :active="open()" />
    <!--        <CollabError :active="showErrorModal" @closed="showErrorModal = false;current_draft = {}" :project="current_draft"/>-->
    <Invite
      :my_project="current_project"
      @start-timer="startTimer = true"
      @closed="onClose($event)"
      @message="onMessage($event)"
      :start_connect="startConnect"
      :type="invite_type"
      :active="openInvite()"
    />
  </v-container>
</template>

<script>
import myServices from "../services/resource";
import Texting from "./Texting";
import Project from "./Project";
import Images from "./MyImages";
import { mapState } from "vuex";
// import AddProject from "../AddProject/AddProject";
import EmptyProject from "./EmptyProject";
import axios from "axios";
import AddCampaign from "./AddCampaign";
import Invite from "./Invite";
import Drafts from "@/components/Drafts";
//import CollabError from "@/components/CollabError/CollabError";

export default {
  components: {
    //CollabError,
    Drafts,
    Invite,
    AddCampaign,
    EmptyProject,
    Images,
    Texting,
    Project,
  },
  data: () => ({
    //removeDraftId: [],
    startConnect: false,
    startTimer: false,
    hover: false,
    radioGroup: 1,
    checkbox: true,
    active: false,
    invite_type: "",
    current_project: {},
    open_invite: false,
    added_project: {},
    //showErrorModal: false,
    current_draft: {},
    stopLoadingId: [],
  }),
  props: {
    ksdailywaiting: Boolean,
    openactivate: Boolean,
  },
  computed: {
    ...mapState({
      projects: "projects",
      draftProjects: "draftProjects",
      user: "user",
    }),
    notInArchive: function () {
      return this.projects.filter((project) => project.inArchive === false);
    },
  },
  watch: {
    open_invite: function (val) {
      if (val) {
        this.startTimer = false;
        this.openInvite();
      }
    },
  },
  beforeCreate() {
    const user = this.$store.state.user;
    if (user.verifiedToken && user.verifiedEmail) {
      axios.put("/api/user/checkExpireTime").then((res) => {
        this.$nextTick(() => {
          this.user.verifiedEmail = res.data.data.verifiedEmail;
        });
      });
    }
  },
  // beforeDestroy() {
  //   window.removeEventListener("online", this.onAfterSleep);
  // },
  methods: {
    // onDeleteDraft(id) {
    //   if(!this.removeDraftId.includes(id)) {
    //     console.log('onDeleteDraft pushed id', id)
    //     this.removeDraftId.push(id)
    //   }
    //   console.log('removeDraftId array', this.removeDraftId)
    // },
    // onAfterSleep() {
    //   console.log('after sleep');
    //   this.$store.dispatch("getDraftProjects").then(() => console.log('length after',this.draftProjects.length));
    // },
    onMessage(e) {
      this.startConnect = false;
      if (e && this.stopLoadingId.includes(e)) {
        const index = this.stopLoadingId.indexOf(e);
        if (index > -1) {
          this.stopLoadingId.splice(index, 1);
        }
      }
    },
    onStartConnect(e) {
      this.startConnect = true;
      if (!this.stopLoadingId.includes(e)) {
        this.stopLoadingId.push(e);
      }
    },
    showDraftAlert() {
      if (this.draftProjects && this.draftProjects.length > 0) {
        if (this.draftProjects[0].status === "FULL_EDITING_RIGHTS_NOT_GIVEN")
          return (
            "<b>We need more!</b> Looks like you forgot to grant us the " +
            (this.draftProjects[0].projectUrl.includes("kickstarter")
              ? "“Edit Project“"
              : "“Full Editing Rights”") +
            " privilege. Please check if you’ve done it correctly, and press the “Check Now” button below."
          );
        if (
          this.draftProjects[0].status === "NO_INVITATION" ||
          this.draftProjects[0].status === "NO_ACCESS" ||
          this.draftProjects[0].status === "CHECKING"
        )
          return "<b>We haven’t received the Collaboration Invitation!</b><br> Make sure to send an invitation during next 10 minutes. If we don’t receive the Invite during that time the project will be removed from the Perkfection platform.";
      }
      return "";
    },
    stopLoad() {
      //console.log('before return draft id', this.stopLoadingId)
      return this.stopLoadingId;
    },
    showInstruction(project) {
      this.invite_type = "instruction";
      this.current_project = project;
      this.open_invite = true;
    },
    addClick() {
      this.active = true;
      myServices.myAnalyticsEvents(
        this,
        "Add campaign",
        "Click to  (+) add campaign icon",
      );
      /*this.$ga.event("Add campaign", "Click to  (+) add campaign icon");
            this.$mixpanel.track("Add campaign", {
              action: "Click to  (+) add campaign icon",
            });*/
    },
    handleNext(project) {
      this.open_invite = true;
      this.current_project = project;
      this.invite_type = "";
    },
    open() {
      return this.active;
    },
    openInvite() {
      return this.open_invite;
    },
    onClose(event) {
      this.current_project = null;
      if (event === "startTimer") {
        this.$store.dispatch("getDraftProjects").then(() => {
          this.startTimer = true;
        });
      }
      this.open_invite = false;
    },
  },
};
</script>
<style scoped>
.draft-alert {
  padding: 13px 22px;
  border-radius: 10px;
  background-color: #fdde86;
  max-width: 650px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 9px;
}
@media only screen and (max-width: 960px) {
  .draft-alert {
    margin: auto;
    position: relative;
    left: 0;
    width: auto;
    transform: translate(0%);
  }
}
</style>
