<template>
  <div>
    <v-dialog
      v-if="dialog"
      persistent
      id="campaign-loading-modal-78178cee"
      v-model="dialog"
      max-width="657px"
      min-wi
    >
      <v-card
        id="campaign-loading-card-b18fab82"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          z-index: inherit;
        "
      >
        <v-container>
          <v-row align="center" style="border-bottom: 1px solid #f4f4f4">
            <v-col cols="1"></v-col>
            <v-col
              cols="auto"
              style="padding-left: 0; padding-top: 17px; padding-bottom: 22px"
            >
              <Texting type="p2bold grey2"
                >Requesting campaign perks data...</Texting
              >
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="10">
              <v-row justify="center" style="padding-bottom: 35px">
                <v-col cols="auto" style="padding-top: 26px">
                  <Texting style="text-align: center" type="p3 dark-asphalt"
                    >It will only take a few seconds...</Texting
                  >
                </v-col>
                <div style="width: 100%"></div>
                <v-col cols="auto" style="padding-top: 28px">
                  <Images name="perk_loading_robot.svg" />
                </v-col>
                <div style="width: 100%; padding-bottom: 27px"></div>
                <v-progress-linear
                  color="#9E33E0"
                  :indeterminate="true"
                ></v-progress-linear>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import { mapState } from "vuex";
import router from "@/plugins/router";

export default {
  components: { Images, Texting },
  props: {
    active: Boolean,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState({
      projects: "projects",
      user: "user",
    }),
  },
  // sockets: {
  //   connect() {
  //     console.log("connected");
  //   },
  //   disconnect() {
  //     console.log("disconnected");
  //   },
  //   hasPerks(data){
  //     console.log(data);
  //     this.dialog = false;
  //     this.$store.dispatch("getProjects");
  //     // this.$socket.client.disconnect();
  //     this.$socket.client.emit("join", { email: this.user.email });
  //   }
  // },
  created() {
    // this.$socket.client.connect()
    // console.log("VUE_APP_BASE_URL: ", process.env.VUE_APP_BASE_URL);
    // console.log("email: ", this.user.email)
    // this.$socket.client.emit("join", { email: this.user.email });

    const notifier = new WebSocket(
      `${process.env.VUE_APP_WS_URL}/?userId=${this.user.userId}&&status=perks`,
    );
    notifier.onmessage = () => {
      this.dialog = false;
      this.$store.dispatch("getProjects");
    };
  },
  // mounted() {
  //   this.socket.on("hasPerks", data => {
  //     console.log(data);
  //     this.dialog = false;
  //     this.$store.dispatch("getProjects");
  //   });
  // },
  watch: {
    active: function (val) {
      this.dialog = val;
    },
    dialog: function (val) {
      if (!val) {
        this.$emit("loading-closed");
      } else {
        setTimeout(() => {
          localStorage.setItem("after_payment_confirmation_canceled", "");
          router.push({ query: Object.assign({}) });
          setTimeout(() => {
            router.go();
          }, 1000);

          // this.$store.dispatch("getProjects");
          // setTimeout(() => {
          //     let my_project = this.projects.filter(
          //         pr =>
          //         pr.projectId === this.$route.query.projectid &&
          //         pr.inArchive === false
          //     )[0];
          //     if (my_project.projectState !== '' && my_project.projectState !== null) {
          //       localStorage.setItem('after_payment_confirmation_canceled', '');
          //     }
          // }, 1000)
        }, 420000);
      }
    },
  },
};
</script>
