<template>
  <div>
    <v-dialog
      @click:outside="goProjects"
      v-if="dialog"
      id="after-payment-modal-4a536a22"
      persistent
      overlay-opacity="0.4"
      v-model="dialog"
      max-width="657px"
      min-wi
    >
      <v-card
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          z-index: inherit;
        "
        id="after-payment-card-5879c754"
      >
        <v-container>
          <v-row align="center" style="border-bottom: 1px solid #f4f4f4">
            <v-col cols="1"></v-col>
            <v-col
              cols="auto"
              style="padding-left: 0; padding-top: 17px; padding-bottom: 22px"
            >
              <Texting type="p2bold grey2">Data Succesfully Transfered</Texting>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="10">
              <v-row justify="center">
                <v-col cols="auto" style="padding-top: 26px">
                  <Texting type="p3 dark-asphalt"
                    >You need to setup your perks now!</Texting
                  >
                </v-col>
                <div style="width: 100%"></div>
                <v-col
                  cols="auto"
                  style="padding-top: 20px; padding-bottom: 40px"
                >
                  <Images name="perk_loading_robot.svg" />
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="auto">
                  <Button
                    id="after-payment-later-button-850894f8"
                    type="cancel"
                    size="normal"
                    texting="p3bold grey2"
                    :handleClick="goProjects"
                    >later</Button
                  >
                </v-col>
                <v-col cols="auto" style="padding-right: 0; padding-left: 3px">
                  <Button
                    id="after-payment-setup-button-8a34917a"
                    type="primary"
                    size="normal"
                    texting="p3bold mywhite"
                    :handleClick="goSetup"
                    >go
                    <span style="text-transform: lowercase">to</span>
                    setup</Button
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import Button from "./Button";
import router from "../plugins/router";
import myServices from "../services/resource";
export default {
  components: {
    Button,
    Images,
    Texting,
  },
  props: {
    active: Boolean,
    project: Object,
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    active: function (val) {
      this.dialog = val;
    },
    dialog: function (val) {
      if (!val) {
        localStorage.setItem("after_payment_confirmation", "false");
        localStorage.setItem("after_payment_confirmation_canceled", "");
      } else {
        this.$confetti.start({
          defaultType: "rect",
          defaultDropRate: 10,
        });
        setTimeout(() => {
          this.$confetti.stop();
        }, 3500);
      }
    },
  },
  methods: {
    goSetup: function () {
      myServices.myAnalyticsEvents(
        this,
        "Activation - complete",
        "go to perk set-up",
      );
      this.dialog = false;
      router.push({
        name: "MyRewards",
        params: {
          projectTitle: this.project.projectTitle,
        },
      });
    },
    goProjects: function () {
      this.dialog = false;
      if (this.$route.name !== "user") {
        router.push({
          name: "user",
        });
      }
    },
  },
};
</script>
