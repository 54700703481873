<template>
  <div>
    <v-dialog
      id="add-campaign-modal-92ee4760"
      overlay-opacity="0.95"
      @click:outside="close"
      v-model="dialog"
      max-width="657px"
      min-wi
    >
      <v-card
        id="add-campaign-card-a9ffbf56"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
        "
      >
        <v-container>
          <form
            action="#"
            ref="form"
            id="AddForm"
            @submit.prevent="onProject"
            autocomplete="off"
            style="overflow: hidden"
          >
            <v-row align="center" style="border-bottom: 1px solid #f4f4f4">
              <v-col cols="1"></v-col>
              <v-col
                cols="auto"
                style="padding-top: 0; padding-bottom: 6px; padding-left: 0"
              >
                <Texting type="p2bold grey2">Add New Campaign</Texting>
              </v-col>
              <v-divider style="border: hidden" />
              <v-col cols="auto" style="padding-top: 8px; padding-right: 20px">
                <Images
                  name="close.svg"
                  class="pointer"
                  v-on:click.native="close"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10" style="padding-left: 0; padding-bottom: 0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Your Campaign Link*"
                      name="projectUrl"
                      id="projectUrl-input-d6f064e8"
                      v-model.trim="form.projectUrl"
                      :class="{
                        'error--text v-input--has-state':
                          ($v.form.projectUrl.$dirty &&
                            !$v.form.projectUrl.required) ||
                          ($v.form.projectUrl.$dirty &&
                            !$v.form.projectUrl.url) ||
                          ($v.form.projectUrl.$error &&
                            !$v.form.projectUrl.kickOrIgg.valid),
                      }"
                      placeholder="https://kickstarter.com/projects/123456/your-campaign-link"
                      onfocus="this.placeholder = ''"
                      hide-details
                      outlined
                    >
                      <template
                        slot="append"
                        v-if="
                          form.projectUrl.includes(
                            'https://www.kickstarter.com/',
                          )
                        "
                      >
                        <Images name="kickstart.svg" style="margin-top: 2px" />
                      </template>
                      <template
                        slot="append"
                        v-else-if="
                          form.projectUrl.includes('https://www.indiegogo.com/')
                        "
                      >
                        <Images name="go.svg" style="margin-top: 2px" />
                      </template>
                      <template
                        slot="append"
                        v-else-if="!$v.form.projectUrl.kickOrIgg"
                      >
                        <v-progress-circular
                          :size="20"
                          color="#9E33E0"
                          width="2"
                          style="margin-top: 3px"
                          indeterminate
                        />
                      </template>
                    </v-text-field>
                    <div class="redColor" style="padding-top: 5px">
                      <Texting
                        type="p1"
                        v-if="
                          $v.form.projectUrl.$dirty &&
                          !$v.form.projectUrl.required
                        "
                        >Url is required</Texting
                      >
                      <Texting
                        type="p1"
                        v-if="
                          $v.form.projectUrl.$dirty && !$v.form.projectUrl.url
                        "
                        >Url format is not valid.</Texting
                      >
                      <Texting
                        type="p1"
                        v-if="
                          $v.form.projectUrl.$error &&
                          !$v.form.projectUrl.kickOrIgg.valid
                        "
                        >Must be 'Kickstarter' or 'Indiegogo'</Texting
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" style="padding-bottom: 0">
                    <v-text-field
                      label="Campaign Title*"
                      name="projectTitle"
                      id="projectTitle-input-099897a8"
                      v-model.trim="form.projectTitle"
                      :class="{
                        'error--text v-input--has-state':
                          ($v.form.projectTitle.$dirty &&
                            !$v.form.projectTitle.required) ||
                          ($v.form.projectTitle.$error &&
                            !$v.form.projectTitle.projectTitleValid.valid &&
                            $v.form.projectTitle.required),
                      }"
                      placeholder="Campaign Title"
                      onfocus="this.placeholder = ''"
                      hide-details
                      outlined
                    ></v-text-field>
                    <div class="redColor" style="padding-top: 5px">
                      <Texting
                        v-if="
                          $v.form.projectTitle.$dirty &&
                          !$v.form.projectTitle.required
                        "
                        type="p1"
                        >Title is required</Texting
                      >
                      <Texting
                        type="p1"
                        v-else-if="
                          $v.form.projectTitle.$error &&
                          !$v.form.projectTitle.projectTitleValid.valid &&
                          $v.form.projectTitle.required &&
                          this.form.projectTitle.length < 60
                        "
                        >Title should contain only letters and numbers
                        (a-z/A-Z/0-9)</Texting
                      >
                      <Texting
                        type="p1"
                        v-else-if="this.form.projectTitle.length >= 60"
                        >Title should be less than 60 characters</Texting
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" style="border-bottom: 1px solid #f4f4f4">
              <v-col
                cols="10"
                style="padding-left: 0; padding-top: 0; padding-bottom: 26px"
              >
                <v-row align="center">
                  <v-col cols="2" style="max-width: 57px">
                    <MySwitch
                      id="add-campaign-email-switch-2cd988e4"
                      :outline="true"
                      @change="onChange"
                      :checked="this.switch"
                    />
                  </v-col>
                  <v-col cols="8" style="padding-left: 28px">
                    <Texting type="p1 grey2"
                      >Email me when my perks are topped up</Texting
                    >
                  </v-col>
                  <div
                    v-if="$v.form.topUpNotificationType.$error"
                    class="redColor"
                  >
                    <Texting>Please choose one</Texting>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10" style="padding-top: 18px; padding-bottom: 4px">
                <v-row justify="end">
                  <v-col cols="auto" style="padding-right: 4px">
                    <Button
                      id="add-campaign-cancel-button-63d5e22a"
                      texting="p2bold grey2"
                      size="normal"
                      :handleClick="close"
                      type="cancel"
                      >cancel</Button
                    >
                  </v-col>
                  <v-col cols="auto">
                    <Button
                      id="add-campaign-next-button-7691071e"
                      b_type="submit"
                      size="normal"
                      :handleClick="nextAction"
                      type="primary"
                      >next</Button
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import myServices from "../services/resource";
import Texting from "./Texting";
import Images from "./MyImages";
import MySwitch from "./MySwitch";
import Button from "./Button";
import axios from "axios";

import { required, url } from "vuelidate/lib/validators";
import { kickOrIgg } from "@/utils/validate";
import { projectTitleValid } from "@/utils/projectTitleValid";

export default {
  components: {
    Button,
    MySwitch,
    Images,
    Texting,
  },
  props: {
    active: Boolean,
  },
  data: () => ({
    dialog: false,
    switch: false,
    projectUrl: "",
    form: {
      projectTitle: "",
      projectUrl: "",
      topUpNotificationType: "NONE",
    },
  }),
  validations: {
    form: {
      projectTitle: {
        required,
        projectTitleValid,
      },
      projectUrl: {
        required,
        url,
        kickOrIgg,
      },
      topUpNotificationType: {
        required,
      },
    },
  },
  watch: {
    active: function (val) {
      if (val) {
        this.dialog = true;
      }
    },
    dialog: function (val) {
      // if (val) {
      //     this.form.projectUrl = '';
      //     this.form.projectTitle = '';
      //     if (localStorage.getItem("draft") !== '' && localStorage.getItem("draft") !== null) {
      //         let title = JSON.parse(localStorage.getItem("draft"))[0];
      //         let time = JSON.parse(localStorage.getItem("draft"))[1];
      //         let now = new Date().getTime();
      //         //console.log('time  ', time)
      //         //console.log('now  ', now)
      //         if (now - time > 600 * 1000) {
      //             this.deleteDraft(title).then(() => {localStorage.setItem("draft", '')});
      //             //localStorage.setItem("draft", '');
      //         }
      //     }
      // }
      if (!val) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    nextAction() {
      // if (localStorage.getItem("draft") !== '' && localStorage.getItem("draft") !== null) {
      //     let title = JSON.parse(localStorage.getItem("draft"))[0];
      //     let time = JSON.parse(localStorage.getItem("draft"))[1];
      //     let now = new Date().getTime();
      //     console.log('time  ', time)
      //     console.log('now  ', now)
      //     if (now - time > 600 * 1000) {
      //         this.deleteDraft(title).then(() => {localStorage.setItem("draft", '')});
      //         //localStorage.setItem("draft", '');
      //     }
      // }
    },
    deleteDraft(projectTitle) {
      axios
        .delete(`/api/draft/deleteDraftProject/${projectTitle}`)
        .catch((error) => {
          this.disabled = false;
          myServices.showErrorMessage(this, error);
        });
    },
    onProject() {
      if (this.$v.$invalid) {
        this.disabled = false;
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }

      axios
        .post("/api/draft/checkDraftProjectBeforeInvite", this.form)
        .then((res) => {
          const project = res.data.data;
          this.$emit("next", project);
          //console.log("project in addcampaign emit next");
          //console.log(project);
          this.switch = false;
          this.close();
        })
        .catch((error) => {
          this.open();
          this.disabled = false;
          myServices.showErrorMessage(this, error);
        });
    },
    open: function () {
      return (this.dialog = true);
    },
    close: function () {
      this.dialog = false;
      this.$v.$reset();
      this.switch = false;
      setTimeout(() => {
        this.form.projectTitle = "";
        this.form.projectUrl = "";
        this.form.topUpNotificationType = "NONE";
      }, 1000);
    },
    // onChange() {
    //   this.switch = !this.switch;
    // }
    onChange: function (val) {
      this.switch = val;
      this.form.topUpNotificationType = val ? "EVERY_TOP_UP" : "NONE";
    },
  },
};
</script>
