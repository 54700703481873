<template>
  <v-row justify="center">
    <v-col cols="auto" style="padding-top: 98px">
      <Images class="balloon" name="frame.svg" />
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="auto">
      <Heading type="h4 grey2">Hey {{ user.displayName }}!</Heading>
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="auto" style="text-align: center; padding-top: 0">
      <Texting v-if="!user.usedFreeTrial" type="p2 grey2"
        >Welcome on board.<br />Start now and
        <span class="labelbold grey10">get 3 days for FREE</span> on your first
        campaign.</Texting
      >
      <Texting v-else type="p2 grey2"
        >You don’t have any projects here, yet.<br />Wanna add one?</Texting
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="auto">
      <div
        style="height: 49px"
        :class="{ point: hover }"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <Images
          id="empty-project-add-icon-ae971e42"
          name="add_project_icon.svg"
          style="position: relative; top: -3px"
          class="pointer"
          @click.native="openModal"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import Heading from "./Heading";
import { mapState } from "vuex";
export default {
  components: {
    Images,
    Texting,
    Heading,
  },
  data: () => ({
    hover: false,
  }),
  computed: {
    ...mapState({
      user: "user",
    }),
  },
  methods: {
    openModal() {
      this.$emit("clicked");
    },
  },
};
</script>
